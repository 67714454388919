export const cardDetails = [
  //   {   
  //       id: 1,
  //       imgUrl: "https://quickvideo.azureedge.net/quickvideo/blogs_images/0c5c.png",
  //       title: "Genpact"
  // },

  // {
  //     id: 2,    
  //     imgUrl: "https://quickvideo.azureedge.net/quickvideo/blogs_images/e817.png",
  //     title: "NYX"
  // },

  // {
  //   id:,
  //     imgUrl: "https://quickvideo.azureedge.net/quickvideo/blogs_images/5651.png",
  //     title: "InnoWave"
  // },

  {
    id:1,
      imgUrl: "https://quickvideo.azureedge.net/quickvideo/blogs_images/68e7.png",
      title: "AgroStar"
  },

  {
    id:2,
      imgUrl: "https://quickvideo.azureedge.net/quickvideo/blogs_images/0e87.png",
      title: "Lalamove"
  },

  {
    id:3,
      imgUrl: "https://quickvideo.azureedge.net/quickvideo/blogs_images/0ff3.png",
      title: "Bajaj Capital"
  },

  // {
  //   id:7,
  //     imgUrl: "https://quickvideo.azureedge.net/quickvideo/blogs_images/7f50.png",
  //     title: "SnapFind",
  // },
  {
    id:4,
      imgUrl: "https://quickvideo.azureedge.net/quickvideo/blogs_images/62cc.png",
      title: "density.exchange",
  },
]