import React, { useEffect } from 'react';
import './Home.css';
import Button from '@mui/material/Button';
import { Fade } from "react-awesome-reveal";
import Hero from './images/hero_img.webp';
import ArrowOutwardIcon from '@mui/icons-material/ArrowOutward';
import VuiBox from 'components/VuiBox';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import { Parallax } from 'react-scroll-parallax';
import { NavLink } from 'react-router-dom/cjs/react-router-dom.min';
import CreateIcon from '@mui/icons-material/Create';
import SlowMotionVideoIcon from '@mui/icons-material/SlowMotionVideo';
import VideoComponent from './VideoComponent';

const Home = () => {
  useEffect(() => {
    document.title = 'QuickVideo | AI Video Generator';

    const metaDescription = document.createElement('meta');
    metaDescription.name = 'description';
    metaDescription.content = 'QuickVideo is an advanced AI Video Generator that simplifies video creation. Create professional studio-quality Videos using AI avatars and voiceovers.';
    document.head.appendChild(metaDescription);
    const script = document.createElement('script');
    script.type = 'application/ld+json';
    const data = {
      "@context": "https://schema.org",
      "@type": "VideoObject",
      "name": "How to create AI videos using QuickVideo",
      "description": "Learn how to effortlessly create AI-powered videos with QuickVideo",
      "uploadDate": "2024-06-06T00:00:00+00:00",
      "duration": "PT10S",
      "contentUrl": "https://quickvideo.azureedge.net/quickvideo/website/Screen%20Recording%202024-06-06%20at%205.mp4?updatedAt=1717676601167",
      "embedUrl": "https://quickvideo.azureedge.net/quickvideo/website/Screen%20Recording%202024-06-06%20at%205.mp4?updatedAt=1717676601167",
      "interactionStatistic": {
        "@type": "InteractionCounter",
        "interactionType": {
          "@type": "http://schema.org/WatchAction"
        },
        "userInteractionCount": 0
      },
      "publisher": {
        "@type": "Organization",
        "name": "QuickVideo"
      }
    };

    script.innerHTML = JSON.stringify(data);
    document.head.appendChild(script);

    return () => {
      document.title = 'QuickVideo';
      document.head.removeChild(metaDescription);
      document.head.removeChild(script);
    };
  }, []);

  return (
    <>
      <div className='home' id='home'>
        {/* <img src={sideHeroImage} alt="hero" width={window.innerWidth <= 800 ? '350px' : '600px'} style={{position: 'absolute', left: '-300px', top: '-200px', zIndex:'-2'}} /> */}
        <div className="background-video">
        <video autoPlay muted loop className='background-inner-video'>
          <source src='https://quickvideo.azureedge.net/quickvideo/website/e0736a86-2013-42ea-bb4e-9f54c90ebb29.mp4?updatedAt=1709534914326' type="video/mp4" />
        </video>
          <div className='home_firstSection'>
            <div className='home_headline'>
              <Fade>
                <h1 style={{fontSize: window.innerWidth <= 800 ? 24 : 34, color: 'azure', textShadow: '1px 0px 4px rgba(50,83,114,0.6)', lineHeight: 1.2, textAlign: 'center'}}>Create Studio Quality Videos using AI</h1>
                {/* <span style={{background: '-webkit-linear-gradient(0deg, #ff0000 25%, #48abe0 100%)', WebkitBackgroundClip: 'text', WebkitTextFillColor: 'transparent', fontSize: '44px', marginLeft: '5px'}}>AI</span></h1> */}
                <p style={{fontWeight: 400, fontSize: window.innerWidth <= 800 ? 12 : 16, marginTop: '5px', textAlign: 'center'}}>QuickVideo is an advanced AI Video Generator that simplifies video creation.<br /> Create professional studio-quality Videos using AI avatars and voiceovers.</p>
                
                <VuiBox sx={{display: window.innerWidth < 800 ? '' : 'flex', alignItems: 'center', textAlign: 'center', justifyContent: 'center'}}>
                  {/* <a href="https://calendly.com/quickvideo/demo" target="_blank" rel="noopener noreferrer">
                    <Button sx={{background: 'white', color: '#000', borderRadius: '20px', fontWeight: 600, fontSize: 12, fontFamily: '"Plus Jakarta Display", Helvetica, Arial, sans-serif !important', marginTop: 3, '&:hover': {color: "#fff"},}} variant='contained'>
                      <CalendarMonthIcon sx={{marginRight: '5px'}} />
                      Book demo
                      <ArrowOutwardIcon sx={{marginLeft: '3px'}} />
                    </Button>
                  </a> */}
                  <a href="https://app.quickvideo.ai/signup" target="_blank" rel="noopener noreferrer">
                    <button  class="button2">
                      <SlowMotionVideoIcon fontSize='small' /> &nbsp;
                      Create free AI Video &nbsp; ➤
                    </button>
                  </a>
                  {/* <a href="https://app.quickvideo.ai/signup" target="_blank" rel="noopener noreferrer">
                    <Button sx={{background: 'white', color: '#000', borderRadius: '30px', fontWeight: 600, fontSize: 12, fontFamily: '"Plus Jakarta Display", Helvetica, Arial, sans-serif !important', marginTop: 3, '&:hover': {color: "#fff"}}} variant='contained'>
                      <SlowMotionVideoIcon /> &nbsp;
                      Create free AI Video &nbsp; ➤
                    </Button>
                  </a> */}
                </VuiBox>
                <p style={{fontWeight: 400, fontSize: window.innerWidth <= 800 ? 10 : 12, textAlign: 'center', color: 'lightgray'}}>No Credit Card Needed</p>
                <VideoComponent />
                {/* <VuiBox sx={{display: window.innerWidth < 800 ? '' : 'flex', alignItems: 'center', textAlign: 'center', justifyContent: 'center'}}>
                  <NavLink to='/createvideomessage'>
                    <Button sx={{background: 'white', color: '#000', borderRadius: '20px', fontWeight: 600, fontSize: 12, fontFamily: '"Plus Jakarta Display", Helvetica, Arial, sans-serif !important', marginTop: window.innerWidth <= 1000 ? '0px' : '20px', '&:hover': {color: "#fff"},}} variant='contained' style={{marginBottom: window.innerWidth <= 800 ? '30px' : ''}}>
                      Create Greeting Message
                      <CreateIcon sx={{marginLeft: '5px'}} />
                    </Button>
                  </NavLink>
                </VuiBox> */}
              </Fade>
            </div>
            {/* {window.innerWidth>=800 && <img src='https://quickvideo.blob.core.windows.net/quickvideo/website_media/hero_img.webp' alt="hero" width={window.innerWidth <= 800 ? '350px' : '500px'} height='500px' style={{marginLeft: window.innerWidth <= 800 ? '' : '-50px', marginBottom: window.innerWidth <= 800 ? '20px' : ''}} loading="lazy" />} */}
            {/* <img src={Hero} alt="hero" width={window.innerWidth <= 800 ? '350px' : '800px'} style={{position: window.innerWidth<=800 ? 'relative' : 'absolute', top: window.innerWidth <= 800 ? -130 : -80, zIndex: window.innerWidth <= 800 ? -1 : -1, right: window.innerWidth<=800 ? 0 : 70, opacity: 1}} /> */}
          </div>
        </div>  
      </div>
    </>
  )
}

export default Home

{/* <video autoPlay muted loop>
          <source src='https://quickvideo.blob.core.windows.net/quickvideo/QV%20Banner%20video.mp4' type="video/mp4" />
        </video> */}