import React, { useEffect, useState } from 'react';
import Modal from '@mui/material/Modal';
import { Box, Button, CircularProgress, Typography } from "@mui/material";
import VuiBox from "components/VuiBox";
import pImage from './../../../Pop-up Image 2.webp';
import CloseIcon from '@mui/icons-material/Close';
import axios from "axios";
import FormField from "layouts/applications/wizard/components/FormField";
import VuiButton from "components/VuiButton";

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '60vw',
  bgcolor: '#000',
  // border: '2px solid gray',
  outline: 'none',
  borderRadius: '15px',
  boxShadow: '0px 0px 100px 5px gray',
  // p: 4,
};

const ExitModal = () => {
  const [openEntryModal, setOpenEntryModal] = useState(false);

  const handleOpen = () => {
    let getFlag = sessionStorage.getItem('modalClosed');
    if(getFlag) {
      setOpenEntryModal(false);
    } else {
      setOpenEntryModal(true);
    }
  };

  const handleClose = () => {
    setOpenEntryModal(false);
    sessionStorage.setItem('modalClosed', false);
  };
  const [emailError, setEmailError] = useState('');
  const [phoneError, setPhoneError] = useState('');
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [businessName, setBusinessSetName] = useState('');
  const [number, setNumber] = useState('');
  const [res, setRes] = useState('');
  const [resToggle, setResToggle] = useState(false);
  const [loader, setLoader] = useState(false);

  useEffect(() => {
    const handleVisibilityChange = () => {
      if (document.hidden) {
        handleOpen();
      }
    };

    const handleBeforeUnload = (event) => {
      handleOpen();
    };

    const timeoutId = setTimeout(() => {
      handleOpen();
    }, 20000);

    // window.addEventListener('beforeunload', handleBeforeUnload);
    document.addEventListener('visibilitychange', handleVisibilityChange);

    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityChange);
      // window.removeEventListener('beforeunload', handleBeforeUnload);
      clearTimeout(timeoutId);
    };
  }, []);

  const postDetails = async () => {
    setLoader(true);
    await axios.post('https://quick.quickvideo.ai/web_leads', {
      full_name: name,
      email_id: email,
      business_name: businessName,
      mobile: number
    })
    .then((res) => {
      setRes(res.data.message);
      handleClose();
      setResToggle(true);
      setLoader(false);
    })
    .catch(err => {
      console.log(err);
      setLoader(false);
    })
  }

  useEffect(()=>{
    setTimeout(() => {
      setResToggle(false);
      setRes('');
      setName('');
      setEmail('');
      setBusinessSetName('');
      setNumber('');
    }, 3000);
  }, [resToggle])

  const handleName = (e) => {
    setName(e.target.value);
  }

  const handleEmail = (e) => {
    setEmail(e.target.value);
    const emailRegex = /^\S+@\S+\.\S+$/;
    if (!emailRegex.test(e.target.value)) {
      setEmailError("Invalid! please enter a valid email.");
    } else {
      setEmailError("");
    }
  }

  const handleBusinessName = (e) => {
    setBusinessSetName(e.target.value);
  }

  const handleNumber = (value) => {
    setNumber(value);
    const phoneRegex = /^[0-9]{10,}$/;
    if (!phoneRegex.test(value.replace(/\D/g, ''))) {
      setPhoneError('Enter valid phone number');
    } else {
      setPhoneError('');
    }
  };

  const phoneInputStyle = {
    // width: '262px',
    height: '38px',
    fontSize: '14px',
    borderRadius: '15px',
    backgroundColor: '#0f1535',
    border: 'none',
    color: 'whitesmoke'
  };

  return (
    <div>
      <Modal
        open={openEntryModal}
        onClose={handleClose}
      >
        <Box sx={style}>
          <VuiBox sx={{display: 'flex', justifyContent: 'space-between', flexDirection: window.innerWidth < 1000 ? 'column' : 'row'}}>
            {window.innerWidth > 1000 && <img loading='lazy' src={pImage} alt="pop-up_image" height='600px' style={{flex: 0.4, borderRadius: '15px'}} />}
            <VuiBox sx={{ flex: '0.6', display: 'flex', flexDirection: 'column', justifyContent: 'flex-start'}}>
              <CloseIcon color="white" fontSize="medium" sx={{margin: '10px 10px 10px auto', cursor: 'pointer'}} onClick={handleClose} />
              <VuiBox sx={{display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', textAlign: 'center', padding: window.innerWidth < 1000 ? '20px' : ''}}>
                <Typography variant="h6" color='gray' sx={{fontSize: window.innerWidth < 1000 ? '12px' : '16px'}}>Wait, There’s More!</Typography>
                <Typography variant="h6" color='whitesmoke' sx={{fontSize: window.innerWidth < 1000 ? '16px' : '24px', lineHeight: 1.1}}>Signup and start creating intuitive videos <br /> within minutes!</Typography>
                <Typography variant="h6" color='whitesmoke' sx={{fontWeight: 400, fontSize: window.innerWidth < 1000 ? '12px' : '', marginTop: window.innerWidth < 1000 ? '20px' : '5px'}}>
                  Fill out this form and our team will reach you with the perfect <br /> solution to create and monetize your videos.
                </Typography>
                <div style={{display: 'flex',alignItems: 'center', flexDirection: 'column', justifyContent: 'space-between', marginTop: '20px'}}>
                  <div style={{display: 'flex',alignItems: 'center', justifyContent: 'space-between', marginBottom: window.innerWidth <= 800 ? '0px' : '20px', flexDirection: 'column'}}>
                    <div style={{width: window.innerWidth<=1000 ? '150px' : '300px'}}>
                      <FormField type="text" label="full name" placeholder="Eg. Michael" onChange={handleName} value={name} />
                    </div>
                    <div style={{width: window.innerWidth<=1000 ? '150px' : '300px'}}>
                      <FormField type="text" label="Business name" placeholder="Eg. QuickVideo" onChange={handleBusinessName} value={businessName} />
                    </div>
                    <div style={{width: window.innerWidth<=1000 ? '150px' : '300px'}}>
                      <FormField type="text" label="email address" placeholder="Eg. micheal@xyz.com" onChange={handleEmail} value={email} />
                      {(emailError && email !== '') && <h6 style={{color: 'red'}}>{emailError}</h6>}
                    </div>
                    <div style={{width: window.innerWidth<=1000 ? '150px' : '300px'}}>
                      <FormField type="phone" label="phone number" placeholder="Eg. 9876543210" onChange={handleNumber} value={number} phoneInputStyle={phoneInputStyle} />
                      {(phoneError && number !== '') && <h6 style={{color: 'red'}}>{phoneError}</h6>}
                    </div>
                  </div>
                  <VuiButton onClick={postDetails} disabled={name === '' || email === '' || number === '' || businessName === '' || phoneError !== '' || emailError !== ''} sx={{marginTop: window.innerWidth < 1000 ? '20px' : ''}}>{loader ? <CircularProgress size={20} /> : 'Schedule Demo'}</VuiButton>
                </div>
              </VuiBox>
            </VuiBox>
          </VuiBox>
        </Box>
      </Modal>
    </div>
  )
}

export default ExitModal