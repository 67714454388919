import VuiBox from 'components/VuiBox'
import VuiTypography from 'components/VuiTypography'
import React, { useRef, useState } from 'react'
import images from './imagedata';
import VuiInput from 'components/VuiInput';
import { Button, CircularProgress, Icon, Typography } from '@mui/material';
import VuiButton from 'components/VuiButton';
import { useEffect } from 'react';
import axios from 'axios';
import cd from './img/compact-disc_7320427.png';
import PlayCircleOutlineIcon from '@mui/icons-material/PlayCircleOutline';
import PauseCircleOutlineIcon from '@mui/icons-material/PauseCircleOutline';
import './animation.css';
import borders from 'assets/theme/base/borders';
import Preview from './Preview';
import html2canvas from 'html2canvas';
import bgImg from './simple-elegant-2024-new-year-eve-holiday-background-design-vector_1017-47054 (2).png';
import AutoFixHighIcon from '@mui/icons-material/AutoFixHigh';
import DriveFileRenameOutlineIcon from '@mui/icons-material/DriveFileRenameOutline';
import BusinessIcon from '@mui/icons-material/Business';
import UploadIcon from '@mui/icons-material/Upload';
import SlowMotionVideoIcon from '@mui/icons-material/SlowMotionVideo';

const PersonalisedMessage = () => {
  const [voices, setVoices] = useState([]);
  const [loading, setLoading] = useState(false);
  const [isPlaying, setIsPlaying] = useState({});
  const [botAvatars, setBotAvatars] = useState();
  const [botAiAvatar, setBotAiAvatar] = useState();
  const [preview, setPreview] = useState(false);
  const [bg, setBg] = useState(null);
  const [selectedBgIndex, setSelectedBgIndex] = useState(null);
  const [activeVoiceId, setActiveVoiceId] = useState(null);
  const [activeImage, setActiveImage] = useState(null);
  const [res, setRes] = useState(null);
  const { borderWidth } = borders;
  const audioRefs = useRef({});
  const [companyName, setCompanyName] = useState('');
  const [messageLoading, setMessageLoading] = useState(false);
  const [details, setDetails] = useState({
    presenter: '',
    message: '',
    voiceId: "21m00Tcm4TlvDq8ikWAM",
    logo: null,
  });
  const [snapshot, setSnapshot] = useState(null);
  const componentRef = useRef(null);

  // const Base_url = 'https://880dfeaf5f2f8ae8260577ee557648be.serveo.net';
  const Base_url = 'https://api.quickvideo.ai/v1';

  // const captureSnapshot = () => {
  //   if (componentRef.current) {
  //     html2canvas(componentRef.current, { useCORS: true }).then((canvas) => {
  //       const dataURL = canvas.toDataURL();
  //       setSnapshot(dataURL);
  //     });
  //   }
  // };
  const captureSnapshot = () => {
    if (componentRef.current) {
      html2canvas(componentRef.current, { useCORS: true }).then((canvas) => {
        const dataURL = canvas.toDataURL();
        setSnapshot(dataURL);
      });
    }
  };

  const togglePlay = (audioUrl) => {
    const newIsPlaying = { [audioUrl]: !isPlaying[audioUrl] };

    // Pause all other audio elements
    Object.keys(audioRefs.current).forEach((url) => {
      if (url !== audioUrl && isPlaying[url]) {
        audioRefs.current[url].pause();
        newIsPlaying[url] = false;
      }
    });

    // Play or pause the selected audio
    if (newIsPlaying[audioUrl]) {
      audioRefs.current[audioUrl].play();
    } else {
      audioRefs.current[audioUrl].pause();
    }
    setIsPlaying(newIsPlaying);
  };

  useEffect(() => {
    const handleEnded = (audioUrl) => {
      setIsPlaying((prevIsPlaying) => ({
        ...prevIsPlaying,
        [audioUrl]: false,
      }));
    };
    // Add event listeners for the "ended" event on each audio element
    Object.keys(audioRefs.current).forEach((audioUrl) => {
      audioRefs.current[audioUrl].addEventListener('ended', () => handleEnded(audioUrl));
    });
    
    return () => {
      Object.keys(audioRefs.current).forEach((audioUrl) => {
        const audioRef = audioRefs.current[audioUrl];
    
        // Check if audioRef is not null before trying to remove the event listener
        if (audioRef) {
          audioRef.removeEventListener('ended', () => handleEnded(audioUrl));
        }
      });
    };
    
  }, []);

  const handleFileUpload = async (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        setDetails({
          ...details,
          logo: e.target.result,
        });
      };
      reader.readAsDataURL(file);
    }
  };

  useEffect(() => {
    fetchAvailableVoices();
  }, [])

  useEffect(() => {
    setLoading(true);
    axios.get('https://api.quickvideo.ai/presenters', {
      params: {
        user_id: "9cb19994-c374-4508-8288-14f4538633f2"
      },
    })
    .then(response => {
      const newAvatars = response.data.presenters;
      const selectedAvatars = newAvatars.slice(17, 28);
      const newAva = newAvatars[30];
      const combinedAvatars = [...selectedAvatars, newAva];
      setBotAvatars(combinedAvatars);
      setBotAiAvatar(newAvatars[0])
      setLoading(false);
    })
    .catch(error => {
      console.error(error);
      setLoading(false);
    });
  }, []);

  const apiKey = '39ac8d95df8d5d45639e30ca0cbcdfbb';

  const fetchAvailableVoices = async () => {
    const url = 'https://api.elevenlabs.io/v1/voices';
    const headers = {
      Accept: 'application/json',
      'xi-api-key': apiKey,
    };

    try {
      const response = await axios.get(url, { headers });
      const premade = response.data.voices.filter((voice) => voice.category === 'premade');
      const selectedVoices = premade.slice(0, 5);
      const newVoice = premade[7];
      const combinedAvatars = [...selectedVoices, newVoice];
      setVoices(combinedAvatars);
    } catch (error) {
      console.error('Error fetching available voices:', error);
      return [];
    }
  };

  const handleThumbnailClick = async (index, id) => {
    setBotAiAvatar(botAvatars[index]);
    setActiveImage(index);
    setDetails({
      ...details,
      presenter: botAvatars[index].image_url,
      presenter_id: botAvatars[index].presenter_id,
    });
  };

  const handleBg = (img, index) => {
    setBg(img);
    setSelectedBgIndex(index);
  }

  const handleVoice = (voice) => {
    setDetails({
      ...details,
      voiceId: voice,
    })
    setActiveVoiceId(voice);
  }

  const handleMessage = (e) => {
    setDetails({
      ...details, 
      message: e.target.value.split('.').join('\n'),
    })
  }

  const handleDiscard = () => {
    setDetails({
      ...details,
      logo: null,
    })
  }
  
  const handleCompanyName = (e) => {
    setCompanyName(e.target.value);
  }

  const generateMessage = async () => {
    setMessageLoading(true);
    try {
      const res = await axios.post('https://quick.quickvideo.ai/generate_ai_text', {
        prompt_text: `Create a four line poem on behalf of ${companyName}, last line should be wishing a very happy new year 2024, to you and your family.`
      })
      
      const newAvailableText = res.data.generated_text;
      setDetails({
        ...details, 
        message: newAvailableText.trim(),
      });
      setMessageLoading(false);

    } catch (err) {
      console.log(err);
      setMessageLoading(false);
    }
  }

  const continueToggle = () => {
    setPreview(true);
  }

  const handleScroll = (direction) => {
    const container = document.getElementById('scrolled');
    const scrollAmount = 500;
  
    if (direction === 'left') {
      container.scrollLeft -= scrollAmount;
    } else {
      container.scrollLeft += scrollAmount;
    }
  };
  
  const handleScrollBg = (direction) => {
    const container = document.getElementById('scrolledbg');
    const scrollAmount = 500;
  
    if (direction === 'left') {
      container.scrollLeft -= scrollAmount;
    } else {
      container.scrollLeft += scrollAmount;
    }
  };
  
  const handleScrollAvatar = (direction) => {
    const container = document.getElementById('scrolledavatar');
    const scrollAmount = 500;
  
    if (direction === 'left') {
      container.scrollLeft -= scrollAmount;
    } else {
      container.scrollLeft += scrollAmount;
    }
  };
  
  // const imgs = images.slice(0, 6);
  return (
    preview ? <Preview details={details} captureSnapshot={captureSnapshot} componentRef={componentRef} snapshot={snapshot} bg={bg} res={res} setRes={setRes} loading={loading} setLoading={setLoading} setPreview={setPreview} baseUrl={Base_url} /> : 
    <>
      <VuiBox sx={{display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: '75%', flexDirection: window.innerWidth < 768 ? 'column' : '', marginLeft: 'auto', marginRight: 'auto'}}>
        <img  loading='lazy' src={bgImg} alt="bg" style={{width: window.innerWidth < 768 ? '150px' : '350px', borderRadius: '20px'}} />
        <VuiTypography variant={window.innerWidth < 768 ? 'h3' : 'h3'} color='white' textAlign='center' sx={{padding: window.innerWidth < 768 ? '5px' : ''}}>Create New Year Wish Video using AI</VuiTypography>
      </VuiBox>

      <VuiBox sx={{display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', margin: '20px 50px'}}>
        <VuiBox sx={{display: 'flex', flexDirection: 'column', width: '100%'}}>
          <VuiTypography variant='h4' color='white' textAlign='center' mb='10px'>Select Video Background</VuiTypography>
          <VuiBox sx={{maxWidth: '100%', position: 'relative' }}>
            <VuiBox id="scrolledbg" sx={{display: 'flex', alignItems: 'center', justifyContent: 'space-between', overflowX: 'auto', overflowY: 'hidden', maxWidth: '100%', scrollBehavior: 'smooth', '::-webkit-scrollbar': { display: 'none' } }}>
              {images.map((img, index) => (
                <VuiBox key={index} sx={{cursor: 'pointer', transition: 'transform .2s', '&:hover': {transform: 'scale(1.05)'}, border: index === selectedBgIndex ? '2px solid blue' : '2px solid transparent', width: '100%'}} onClick={() => handleBg(img, index)}>
                  <img src={img} alt={index} width={window.innerWidth < 768 ? '150px' : '360px'} height={window.innerWidth < 768 ? '100px' : '200px'} style={{margin: '0px 10px'}}  loading='lazy' />
                </VuiBox>
              ))}
            </VuiBox>
            <VuiBox
              onClick={() => handleScrollBg('left')}
              sx={{ position: 'absolute', top: '50%', left: '10px', transform: 'translateY(-50%)', cursor: 'pointer', zIndex: '1', color: 'whitesmoke' }}
            >
              ❮
            </VuiBox>
            <VuiBox
              onClick={() => handleScrollBg('right')}
              sx={{ position: 'absolute', top: '50%', right: '10px', transform: 'translateY(-50%)', cursor: 'pointer', zIndex: '1', color: 'whitesmoke' }}
            >
              ❯
            </VuiBox>
          </VuiBox>
        </VuiBox>

        <VuiBox sx={{display: 'flex', flexDirection: 'column', width: '100%', marginTop: '50px'}}>
          <VuiTypography variant='h4' color='white' textAlign='center'>Select Audio</VuiTypography>
          <VuiBox sx={{ overflowX: 'hidden', maxWidth: '100%', position: 'relative' }}>
            <VuiBox id="scrolled" sx={{display: 'flex', alignItems: 'center', justifyContent: 'space-evenly', overflowX: 'auto', maxWidth: '100%', scrollBehavior: 'smooth', marginTop: '10px', '::-webkit-scrollbar': { display: 'none' }, flexWrap: window.innerWidth < 768 ? 'wrap' : '' }}>
              {voices.map((voice, index) => (
                <VuiBox key={index} onClick={() => handleVoice(voice.voice_id)} sx={{ border: `1px solid ${voice.voice_id === activeVoiceId ? 'blue' : 'gray'}`, margin: window.innerWidth < 768 ? '5px' : '0 25px', borderRadius: '5px', padding: '5px', minWidth: window.innerWidth < 768 ? '130px' : '170px', display: 'flex', flexDirection: 'row', alignItems: 'center', cursor: 'pointer', transition: 'transform .2s', '&:hover': {transform: 'scale(1.02)'}}}>
                  <img src='https://quickvideo.azureedge.net/quickvideo/features_page/compact-disc_7320427.png ' alt="disc" width={window.innerWidth < 768 ? '40px' : '60px'} style={{marginBottom: '5px',}} className={isPlaying[voice.preview_url] ? 'rotate' : ''}  loading='lazy' />
                  <VuiBox sx={{display: 'flex', flexDirection: 'column', alignItems: 'flex-start', marginLeft: '20px'}}>
                    <VuiTypography variant='h6' color='white'>{voice.name}</VuiTypography>
                    <VuiTypography variant='h6' color='white' sx={{fontSize: '14px'}}>({voice.labels.gender})</VuiTypography>
                    <audio ref={(ref) => (audioRefs.current[voice.preview_url] = ref)} src={voice.preview_url} />
                    <Button color='white' sx={{marginLeft: '-25px'}} onClick={() => togglePlay(voice.preview_url)}>
                      {isPlaying[voice.preview_url] ? <PauseCircleOutlineIcon fontSize='30px !important' /> : <PlayCircleOutlineIcon fontSize='30px !important' />}
                    </Button>
                  </VuiBox>
                </VuiBox>
              ))}
            </VuiBox>

            {/* <VuiBox
              onClick={() => handleScroll('left')}
              sx={{ position: 'absolute', top: '50%', left: '10px', transform: 'translateY(-50%)', cursor: 'pointer', zIndex: '1', color: 'whitesmoke' }}
            >
              ❮
            </VuiBox>
            <VuiBox
              onClick={() => handleScroll('right')}
              sx={{ position: 'absolute', top: '50%', right: '10px', transform: 'translateY(-50%)', cursor: 'pointer', zIndex: '1', color: 'whitesmoke' }}
            >
              ❯
            </VuiBox> */}
          </VuiBox>
        </VuiBox>

        <VuiBox sx={{display: 'flex', flexDirection: 'column', width: '100%', marginTop: '50px'}}>
          <VuiTypography variant='h4' color='white' textAlign='center'>Select Presenter</VuiTypography>
          <VuiBox sx={{ overflowX: 'hidden', maxWidth: '100%', position: 'relative' }}>
            <VuiBox id='scrolledavatar' sx={{display: 'flex', alignItems: 'center', justifyContent: window.innerWidth < 768 ? 'flex-start' : 'space-between', overflowX: 'auto', maxWidth: '100%', scrollBehavior: 'smooth', '::-webkit-scrollbar': { display: 'none' }, flexWrap: window.innerWidth < 768 ? 'wrap' : '', marginLeft: window.innerWidth < 768 ? '5%' : '' }}>
              {botAvatars && botAvatars.map((item, index) => (
                <VuiBox
                  key={index}
                  mt={1}
                  sx={{
                    display: 'flex',
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <Button
                    borderRadius="100%"
                    width= {activeImage === index ? "73px" : "70px"}
                    height= {activeImage === index ? "73px" : "70px"}
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    color="white"
                    // mx="10px"
                    border={`${borderWidth[1]} solid`}
                    sx={{ cursor: loading ? "wait" : "pointer"}}
                    onClick={() => handleThumbnailClick(index, item.id)}
                    disabled={loading}
                  >
                    <img
                      src={item.image_url}
                      alt='avatar'
                      style={{
                        borderRadius: "100%",
                        width: activeImage === index ? "67px" : "67px",
                        height:  activeImage === index ? "67px" : "67px",
                        background: "rgb(241, 242, 246)",
                        objectFit: "cover",
                        border: activeImage === index ? "3px solid darkblue" : "none",
                        transition: 'box-shadow 0.3s ease, transform 0.3s ease',
                      }}
                      onMouseOver={(e) => {
                        e.target.style.transform = "scale(1.1)";
                        e.target.style.boxShadow = "0px 0px 20px 0px rgba(255,255,255,0.5)"
                      }}
                      onMouseOut={(e) => {
                        e.target.style.transform = "none";
                        e.target.style.boxShadow = "none";
                      }}
                      loading='lazy'
                    />
                  </Button>
                </VuiBox>
              ))}
            </VuiBox>
            {/* <VuiBox
              onClick={() => handleScrollAvatar('left')}
              sx={{ position: 'absolute', top: '50%', left: '10px', transform: 'translateY(-50%)', cursor: 'pointer', zIndex: '1', color: 'whitesmoke' }}
            >
              ❮
            </VuiBox>
            <VuiBox
              onClick={() => handleScrollAvatar('right')}
              sx={{ position: 'absolute', top: '50%', right: '10px', transform: 'translateY(-50%)', cursor: 'pointer', zIndex: '1', color: 'whitesmoke' }}
            >
              ❯
            </VuiBox> */}
          </VuiBox>
        </VuiBox>

        <VuiBox sx={{display: 'flex', flexDirection: 'column', width: '100%', marginTop: '50px', alignItems: 'center'}}>
          <VuiTypography variant='h4' color='white' textAlign='center'>Company Logo (optional)</VuiTypography>
          {details.logo !== null && <VuiBox sx={{maxHeight: '30vh', maxWidth: '100%', height: '20vh', width: '300px', background: '#000', marginBottom: '10px', display: 'flex', alignItems: 'center', justifyContent: 'center', marginTop: '10px'}}>
          <img alt='bg' src={details.logo} style={{ maxWidth: '100%', maxHeight: '18vh' }} loading='lazy' />
          </VuiBox>
          }
          <VuiBox sx={{display: 'flex', alignItems: 'center', width: '400px', justifyContent: 'center', marginTop: '10px'}}>
            <label htmlFor="image-file" style={{cursor: 'pointer'}}>
              <Typography variant='h6' sx={{color: '#000', background: 'white', padding: '8px 25px', borderRadius: '15px'}}>
                <UploadIcon />
                <input
                  accept="image/jpeg, image/png, image/jpg"
                  style={{ display: 'none' }}
                  type="file"
                  id="image-file"
                  name="image-file"
                  onChange={handleFileUpload}
                />
              </Typography>
            </label>
            {details.logo && <Button variant='contained' color='white' sx={{marginLeft: window.innerWidth < 768 ? '20px' : 'auto', fontSize: window.innerWidth < 768 ? '12px' : '14px', color: '#000'}} onClick={handleDiscard}>
              discard
            </Button>}
          </VuiBox>
        </VuiBox>

        <VuiBox sx={{display: 'flex', flexDirection: 'column', alignItems: 'center', width: '100%', marginTop: '50px'}}>
          <VuiBox sx={{display: 'flex', alignItems: 'center', marginBottom: '10px'}} >
            <AutoFixHighIcon size={24} color='white' />
            <VuiTypography variant='h4' ml='10px' color='white' textAlign='center'>Generate New Year Wish</VuiTypography>
          </VuiBox>
          {details.message === '' ? 
          <>
            {messageLoading ? <CircularProgress  size={22} color='white' /> : <>
              <VuiInput
                icon={{
                  component: (
                    <Icon>
                      <BusinessIcon />
                    </Icon>
                  ),
                  direction: "right",
                }}
                placeholder='Enter your company name ..'
                sx={{width: window.innerWidth < 768 ? '200px !important' : '500px !important', margin: '10px auto'}}
                onChange={(e) => handleCompanyName(e)}
                value={companyName}
              />
              <VuiButton sx={{marginTop: '10px', fontSize: window.innerWidth < 768 ? '12px' : '14px'}} onClick={generateMessage}>
                Generate message &nbsp; ➜
              </VuiButton>
            </> }
          </>
           : <VuiInput
              placeholder='Enter your message here ..'
              sx={{width: window.innerWidth < 768 ? '250px !important' : '500px !important', margin: '10px auto'}}
              multiline
              minRows={4}
              maxRows={10}
              onChange={(e) => handleMessage(e)}
              value={details.message}
            />}
        </VuiBox>

        

        {details.message !== '' && <Button 
          variant='contained'
          onClick={continueToggle} 
          sx={{display: 'flex', marginTop: '70px', marginLeft: 'auto', fontSize: window.innerWidth < 768 ? '14px' : '16px', color: 'whitesmoke', '&:disabled': {background: 'gray', color: '#000'}}} 
          disabled={details.message === '' || details.presenter === '' || messageLoading || bg === null}
        >
          Preview Video
          <SlowMotionVideoIcon fontSize='medium' sx={{marginLeft: '5px'}} color='white' />
        </Button>}
      </VuiBox>
    </>
  )
}

export default PersonalisedMessage