import React, { useEffect } from 'react';
import { Button, Card, Typography } from '@mui/material';
import './Solutions.css';
// import bannerVideo from './images/texttovideo/Banner copy.mp4';
import bannerVideo from './videos/Banner.mp4';
import textSlide from './images/texttovideo/Text Slide-1 (1).png';
import first from './images/texttovideo/Avatars-1.png';
import second from './images/texttovideo/Text to Video-1 (1).png';
import third from './images/texttovideo/Generating Video-1.png';
import arrow from './images/texttovideo/right-arrow_2252525.png';
import VuiTypography from 'components/VuiTypography';
import { Fade, Zoom } from "react-awesome-reveal"; 
import firstIcon from './images/texttovideo/Multiple Languages.webp';
import secondIcon from './images/texttovideo/Creativity.webp';
import thirdIcon from './images/texttovideo/Save time.webp';
import fourthIcon from './images/texttovideo/Audience.webp';
import fifthIcon from './images/texttovideo/Wide Range.webp';
import downArrow from './images/texttovideo/down-arrow_266.png';
import ArrowOutwardIcon from '@mui/icons-material/ArrowOutward';
import VuiBox from 'components/VuiBox';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import { Parallax } from 'react-scroll-parallax';
import Form from '../Form/Form';

const TextToVideo = () => {

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    // Update the title
    document.title = 'Text to video AI Generator by QuickVideo';

    // Add a meta description tag
    const metaDescription = document.createElement('meta');
    metaDescription.name = 'description';
    metaDescription.content = 'Experience the power of QuickVideo’s cutting-edge text To Video AI generator tool. Engage your audience like never before with the ultimate text-to-video experience.';
    document.head.appendChild(metaDescription);

    const gtmScript = document.createElement('script');
    gtmScript.innerHTML = `
      (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start': new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0], j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src= 'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);})(window,document,'script','dataLayer','GTM-MLKZZRFP');
    `;
    document.head.appendChild(gtmScript);

    const script = document.createElement('script');
    script.type = 'application/ld+json';
    const data = {
      "@context": "https://schema.org",
      "@type": "VideoObject",
      "name": "Create Stunning AI-Powered Videos Effortlessly with QuickVideo",
      "description": "Discover how QuickVideo's AI technology makes creating captivating videos quick and easy—perfect for enhancing your content strategy.",
      "uploadDate": "2024-08-06T00:00:00+00:00",
      "duration": "PT13S",
      "contentUrl": "https://quickvideo.blob.core.windows.net/quickvideo/website_media/Banner.mp4",
      "embedUrl": "https://quickvideo.blob.core.windows.net/quickvideo/website_media/Banner.mp4",
      "interactionStatistic": {
        "@type": "InteractionCounter",
        "interactionType": {
          "@type": "http://schema.org/WatchAction"
        },
        "userInteractionCount": 0
      },
      "publisher": {
        "@type": "Organization",
        "name": "QuickVideo"
      }
    };

    script.innerHTML = JSON.stringify(data);
    document.head.appendChild(script);

    return () => {
      document.title = 'QuickVideo';
      document.head.removeChild(metaDescription);
      document.head.removeChild(gtmScript);
      document.head.removeChild(script);
    };
  }, []);

  const redirectToSignUp = () => {
    window.open('https://app.quickvideo.ai/signup', '_blank');
  };
  
  return (
    <div className='texttovideo'>
      <Parallax speed={20}>
        <div className="texttovideo_banner">
          <div style={{marginBottom: window.innerWidth <= 800 ? '50px' : ''}}>
            <h1 style={{fontSize: window.innerWidth <= 1000 ? 26 : 36, color: '#3692DD', textShadow: '1px 0px 4px rgba(50,83,114,0.6)', lineHeight: 1.2, textAlign: window.innerWidth < 768 ? 'center' : ''}}>Text-to-Video AI{window.innerWidth <= 800 ? '' : <br />} Generator tool </h1>
            <h5 style={{fontWeight: 400, fontSize: window.innerWidth <= 800 ? 14 : '', textAlign: window.innerWidth < 768 ? 'center' : ''}}>Quickvideo.ai  is a smart Text-to-Video generator tool that simplifies {window.innerWidth <= 1000 ? '' : <br />} the creation of personalized and professional Ai-generated Videos {window.innerWidth <= 1000 ? '' : <br />} for a better customer experience.</h5>
            <VuiBox sx={{display: window.innerWidth < 800 ? '' : 'flex', alignItems: 'center', textAlign: 'center'}}>
              <a href="https://calendly.com/quickvideo/demo" target="_blank" rel="noopener noreferrer">
                <Button sx={{background: 'white', color: '#000', borderRadius: '20px', fontWeight: 600, fontSize: 12, fontFamily: '"Plus Jakarta Display", Helvetica, Arial, sans-serif !important', marginTop: 3, '&:hover': {color: "#fff"},}} variant='contained'>
                  <CalendarMonthIcon sx={{marginRight: '5px'}} />
                  Book demo
                  <ArrowOutwardIcon sx={{marginLeft: '3px'}} />
                </Button>
              </a>
              <a href="https://app.quickvideo.ai/signup" target="_blank" rel="noopener noreferrer">
                <Button sx={{background: 'white', color: '#000', borderRadius: '30px', fontWeight: 600, fontSize: 12, fontFamily: '"Plus Jakarta Display", Helvetica, Arial, sans-serif !important', marginTop: 3, '&:hover': {color: "#fff"}, marginLeft: (window.innerWidth < 800 && window.innerWidth > 440) ? 1 : window.innerWidth < 440 ? 0 : 2}} variant='contained'>
                  Start free trial
                  <KeyboardArrowRightIcon />
                </Button>
              </a>
            </VuiBox>
          </div>
          <video height={window.innerWidth <= 1000 ? 'auto' : 350} width={window.innerWidth <= 1000 ? '300px' : ''} muted autoPlay loop>
            <source src='https://quickvideo.blob.core.windows.net/quickvideo/website_media/Banner.mp4' type="video/mp4" />
          </video>
        </div>
      </Parallax>
      <img src='https://quickvideo.blob.core.windows.net/quickvideo/website_media/Text Slide-1.png' alt="textslide" width={window.innerWidth <= 1200 ? 280 : 1000} style={{marginTop:window.innerWidth <= 800 ? '50px' : '150px', marginLeft: 'auto', marginRight: 'auto'}} loading="lazy" />
      <Parallax speed={30}>
        <div className='texttovideo_personalized'>
          <h3 style={{marginLeft: 'auto', marginRight: 'auto', textAlign: window.innerWidth <= 800 ? 'center' : '' }}>Generate a Personalized Video in 3 steps</h3>
          <div style={{display: 'flex', alignItems: 'center', justifyContent: 'space-around', marginTop: '50px', marginBottom: '50px', flexDirection: window.innerWidth <= 1200 ? 'column' :'row' }}>
            <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' ,textAlign: 'center', width: 330, marginTop: window.innerWidth <= 800 ? '30px' : ''}}>
              <img src='https://quickvideo.blob.core.windows.net/quickvideo/website_media/Avatars-1.png' alt="first step" width={300} loading="lazy" />
              <VuiTypography variant='h5' sx={{color: 'lightblue !important', marginTop: '5px'}}>Select an Avatar along with Speech & Language</VuiTypography>
            </div>
            {window.innerWidth >= 1200 && <img src={arrow} alt="arrow" width={100} style={{filter: 'invert(1)'}} loading="lazy" />}
            {window.innerWidth < 1200 && <img src={downArrow} alt="arrow" width={100} style={{filter: 'invert(1)'}} loading="lazy" />}
            <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' ,textAlign: 'center', width: 330, marginTop: window.innerWidth <= 800 ? '30px' : ''}}>
              <img src='https://quickvideo.blob.core.windows.net/quickvideo/website_media/Text to Video-1 (1).png' alt="second step" width={300} loading="lazy" />
              <VuiTypography variant='h5' sx={{color: 'lightblue !important', marginTop: '5px'}}>Simply enter the text you want</VuiTypography>
            </div>
            {window.innerWidth >= 1200 && <img src={arrow} alt="arrow" width={100} style={{filter: 'invert(1)'}} loading="lazy" />}
            {window.innerWidth < 1200 && <img src={downArrow} alt="arrow" width={100} style={{filter: 'invert(1)'}} loading="lazy" />}
            <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'space-between' ,textAlign: 'center', width: 330, marginTop: window.innerWidth <= 800 ? '30px' : ''}}>
              <img src='https://quickvideo.blob.core.windows.net/quickvideo/website_media/Generating Video-1.png' alt="third step" width={300} loading="lazy" />
              <VuiTypography variant='h5' sx={{color: 'lightblue !important', marginTop: '5px'}}>Make the final Touches and hit Generate</VuiTypography>
            </div>
          </div>
          <h3 style={{marginLeft: 'auto', marginRight: 'auto'}}>It's that simple!</h3>
        </div>
      </Parallax>
      <div className="horizontal_line"></div>
      <div className='home_secondSection' style={{marginTop: '100px'}}>
        <Typography variant='h3' sx={{fontSize: window.innerWidth <= 800 ? 14 : 24, fontWeight: 600, color: 'whitesmoke', marginBottom: '50px'}}>Why Choose Quickvideo?</Typography>
        <div className='box'>
          <div style={{display: 'flex', alignItems: 'center', justifyContent: 'space-evenly', flexWrap: 'wrap'}}>
            <Card sx={{background: 'linear-gradient(#060B28F0, #0A0E237D, #0A0E23B0)', color: 'whitesmoke', borderRadius: 5, minHeight: window.innerWidth <= 800 ? 300 : 280}} className='card'>
              <Fade>
                <img src='https://quickvideo.blob.core.windows.net/quickvideo/website_media/Multiple Languages.webp' alt="img" width="80px" loading="lazy" />
                <Typography variant='h6' sx={{fontWeight: 600, fontSize: 18, marginTop: 1, color: 'whitesmoke'}}>Videos in Multiple Languages</Typography>
                <Typography sx={{fontWeight: '400px !important', fontSize: 14, marginTop: 1.5, color: 'whitesmoke'}}>
                  Use Quickvideo to create captivating videos voiced in multiple languages. Just choose the Speech and language you desire.
                </Typography>
              </Fade>
            </Card>
            <Card sx={{background: 'linear-gradient(#060B28F0, #0A0E237D, #0A0E23B0)', color: 'whitesmoke', borderRadius: 5, minHeight: window.innerWidth <= 800 ? 300 : 280}} className='card'>
              <Fade>
                <img src='https://quickvideo.blob.core.windows.net/quickvideo/website_media/Creativity.webp' alt="img" width="80px" loading="lazy" />
                <Typography variant='h6' sx={{fontWeight: 600, fontSize: 18, marginTop: 1, color: 'whitesmoke'}}>Unleash Your Creativity</Typography>
                <Typography sx={{fontWeight: '400px !important', fontSize: 14, marginTop: 0.5, color: 'whitesmoke'}}>
                  Let your imagination run wild! Quickvideo’s Text-to-Video AI Generator turns your words into engaging videos. 
                </Typography>
              </Fade>
            </Card>
            <Card sx={{background: 'linear-gradient(#060B28F0, #0A0E237D, #0A0E23B0)', color: 'whitesmoke', borderRadius: 5, minHeight: window.innerWidth <= 800 ? 300 : 280}} className='card'>
              <Fade>
                <img src='https://quickvideo.blob.core.windows.net/quickvideo/website_media/Save time.webp' alt="img" width="80px" loading="lazy" />
                <Typography variant='h6' sx={{fontWeight: 600, fontSize: 18, marginTop: 1, color: 'whitesmoke'}}>Save Time and Effort</Typography>
                <Typography sx={{fontWeight: '400px !important', fontSize: 14, marginTop: 0.5, color: 'whitesmoke'}}>
                  No need to spend hours learning complex video editing software or hiring expensive specialists. With our tool, you can create professional videos in minutes, not days.
                </Typography>
              </Fade>
            </Card>
          </div>
          <div style={{display: 'flex', alignItems: 'center', justifyContent: 'space-evenly', flexWrap: 'wrap'}}>
            <Card sx={{background: 'linear-gradient(#060B28F0, #0A0E237D, #0A0E23B0)', color: 'whitesmoke', borderRadius: 5, minHeight: window.innerWidth <= 800 ? 300 : 280}} className='card'>
              <Fade>
                <img src='https://quickvideo.blob.core.windows.net/quickvideo/website_media/Audience.webp' alt="img" width="80px" loading="lazy" />
                <Typography variant='h6' sx={{fontWeight: 600, fontSize: 18, marginTop: 1, color: 'whitesmoke'}}>Engage Your Audience</Typography>
                <Typography sx={{fontWeight: '400px !important', fontSize: 14, marginTop: 0.5, color: 'whitesmoke'}}>
                  Videos are one of the most effective ways to capture and maintain your audience's attention. Our AI ensures your videos are impactful and thus retained by your viewers, helping you get your message across.
                </Typography>
              </Fade>
            </Card>
            <Card sx={{background: 'linear-gradient(#060B28F0, #0A0E237D, #0A0E23B0)', color: 'whitesmoke', borderRadius: 5, minHeight: window.innerWidth <= 800 ? 300 : 280}} className='card'>
              <Fade>
                <img src='https://quickvideo.blob.core.windows.net/quickvideo/website_media/Wide Range.webp' alt="img" width="80px" loading="lazy" />
                <Typography variant='h6' sx={{fontWeight: 600, fontSize: 18, marginTop: 1, color: 'whitesmoke'}}>Wide Range of Uses</Typography>
                <Typography sx={{fontWeight: '400px !important', fontSize: 14, marginTop: 0.5, color: 'whitesmoke'}}>
                  Whether it's for social media marketing, educational content, product promotions, or storytelling, Quickvideo’s Text-to-Video Generator tool is versatile and adaptable to all your needs.
                </Typography>
              </Fade>
            </Card>
          </div>
        </div>
      </div>
      <div style={{marginTop: '50px'}}>
        <Form />
      </div>
      <noscript>
        <iframe src="https://www.googletagmanager.com/ns.html?id=GTM-MLKZZRFP" height="0" width="0" style="display:none;visibility:hidden"></iframe>
      </noscript>
    </div>
  )
}

export default TextToVideo