/** 

=========================================================
* Vision UI PRO React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/vision-ui-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com/)

* Design and Coded by Simmmple & Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Visionware.

*/

// @mui material components
import Grid from "@mui/material/Grid";
import Icon from "@mui/material/Icon";
import VuiBox from "components/VuiBox";
import VuiTypography from "components/VuiTypography";
import VuiAvatar from "components/VuiAvatar";
import VuiButton from "components/VuiButton";
import FormField from "layouts/applications/wizard/components/FormField";
import { useState } from "react";
import { CircularProgress, Typography } from "@mui/material";

function About({name, setName, email, setEmail, businessName, setBusinessSetName, number, setNumber, postDetails, res, loader, resToggle}) {
  const [emailError, setEmailError] = useState('');
  const [phoneError, setPhoneError] = useState('');
  const [nameError, setNameError] = useState('');
  const [businessNameError, setBusinessNameError] = useState('');
  const [isPhoneFocused, setIsPhoneFocused] = useState(false);

  const handleName = (e) => {
    setName(e.target.value);
    // Check if the input contains any numbers
    if (/[0-9]/.test(e.target.value)) {
      setNameError('Name should not contain numbers.');
    } else {
      setNameError('');
    }
  };

  const handleBusinessName = (e) => {
    const input = e.target.value;

    // Check if the input contains any numbers
    if (/[0-9]/.test(input)) {
      setBusinessNameError('Business name should not contain numbers.');
    } else {
      setBusinessNameError('');
    }
    setBusinessSetName(input);
  };

  const handleEmail = (e) => {
    setEmail(e.target.value);
    const emailRegex = /^\S+@\S+\.\S+$/;
    if (!emailRegex.test(e.target.value)) {
      setEmailError("Invalid! please enter a valid email.");
    } else {
      setEmailError("");
    }
  }

  const handleNumber = (value) => {
    setNumber(value);
    const phoneRegex = /^[0-9]{10,}$/;
    if (!phoneRegex.test(value.replace(/\D/g, ''))) {
      setPhoneError("Enter valid phone number");
    } else {
      setPhoneError("");
    }
  };

  const phoneInputStyle = {
    width: '100%',
    height: '38px',
    fontSize: '14px',
    borderRadius: '15px',
    backgroundColor: '#0f1535',
    border: '0.0625rem solid #56577a',
    color: 'whitesmoke',
    ...isPhoneFocused && { boxShadow: '0rem 0rem 0rem 0.125rem rgba(98, 143, 194, 1)' },
  };

  return (
    <>
      {loader ? (
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', minHeight: '50vh' }}>
          <CircularProgress color="white" size={30} />
        </div>
      ) : (
        <>
          {resToggle === true ? (
            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', minHeight: '50vh', color: 'whitesmoke', fontSize: 30 }}>
              {res}
            </div>
          ) : (
            <>
              <VuiBox width="100%" textAlign="center">
                <VuiBox mb={1}>
                  <VuiTypography variant="h3" fontWeight="regular" color="white">
                    Start generating your AI Videos today!
                  </VuiTypography>
                </VuiBox>
                <VuiTypography variant="body1" fontWeight="regular" color="text">
                  No credit card required
                </VuiTypography>
              </VuiBox>
              <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                <div style={{ width: window.innerWidth <= 800 ? '200px' : '500px' }}>
                  <FormField type="text" label="full name" placeholder="Eg. Michael" onChange={handleName} value={name} />
                  {nameError && name !== '' && <h6 style={{ color: 'red' }}>{nameError}</h6>}
                </div>
                <div style={{ width: window.innerWidth <= 800 ? '200px' : '500px' }}>
                  <FormField type="text" label="Business name" placeholder="Eg. QuickVideo" onChange={handleBusinessName} value={businessName} />
                  {businessNameError && businessName !== '' && <h6 style={{ color: 'red' }}>{businessNameError}</h6>}
                </div>
                <div style={{ width: window.innerWidth <= 800 ? '200px' : '500px' }}>
                  <FormField type="text" label="email address" placeholder="Eg. micheal@xyz.com" onChange={handleEmail} value={email} />
                  {emailError && email !== '' && <h6 style={{ color: 'red' }}>{emailError}</h6>}
                </div>
                <div style={{ width: window.innerWidth <= 800 ? '200px' : '500px' }}>
                   <FormField
                      type="phone"
                      label="phone number"
                      placeholder="Eg. 9876543210"
                      onChange={handleNumber}
                      value={number}
                      onFocus={() => setIsPhoneFocused(true)}
                      onBlur={() => setIsPhoneFocused(false)}
                      phoneInputStyle={phoneInputStyle}
                    />
                </div>
                <VuiButton
                  sx={{ marginLeft: window.innerWidth <= 800 ? 0 : '410px', marginTop: '20px' }}
                  onClick={postDetails}
                  disabled={
                    name === '' ||
                    email === '' ||
                    number === '' ||
                    businessName === '' ||
                    phoneError !== '' ||
                    emailError !== '' ||
                    businessNameError !== '' ||
                    nameError !== ''
                  }
                >
                  Submit
                </VuiButton>
              </div>
            </>
          )}
        </>
      )}
    </>
  );
}

export default About;