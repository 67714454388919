import React, { useEffect } from 'react';
import './Solutions.css';
import bannerVideo from './videos/Conversational Video.mp4';
import { Button } from '@mui/material';
import PersonalizedCustomer from './images/conversational/personalized-customer-service-1.png';
import Personify from './images/conversational/personify-interactions-1.png';
import Boost from './images/conversational/Boost Brand Value-1.png';
import textSlide from './images/conversational/Text Slide-1.png';
import ArrowOutwardIcon from '@mui/icons-material/ArrowOutward';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import VuiBox from 'components/VuiBox';
import { Parallax } from 'react-scroll-parallax';
import Form from '../Form/Form';

const Conversational = () => {

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    document.title = 'Real-time Conversational AI Chatbot | QuickVideo';

    const metaDescription = document.createElement('meta');
    metaDescription.name = 'description';
    metaDescription.content = "Experience the power of real-time conversational AI chatbots with QuickVideo's. Enhance your customer engagement and streamline your operations.";
    document.head.appendChild(metaDescription);

    const gtmScript = document.createElement('script');
    gtmScript.innerHTML = `
      (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start': new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0], j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src= 'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);})(window,document,'script','dataLayer','GTM-MLKZZRFP');
    `;
    document.head.appendChild(gtmScript);

    const script = document.createElement('script');
    script.type = 'application/ld+json';
    const data = {
      "@context": "https://schema.org",
      "@type": "VideoObject",
      "name": "Transform Your Brand with Conversational Video | QuickVideo.ai",
      "description": "Discover how QuickVideo.ai's conversational video tool can revolutionize your marketing strategy with engaging, personalized content.",
      "uploadDate": "2024-08-06T00:00:00+00:00",
      "duration": "PT9S",
      "contentUrl": "https://quickvideo.blob.core.windows.net/quickvideo/website_media/Conversational Video.mp4",
      "embedUrl": "https://quickvideo.blob.core.windows.net/quickvideo/website_media/Conversational Video.mp4",
      "interactionStatistic": {
        "@type": "InteractionCounter",
        "interactionType": {
          "@type": "http://schema.org/WatchAction"
        },
        "userInteractionCount": 0
      },
      "publisher": {
        "@type": "Organization",
        "name": "QuickVideo"
      }
    };

    script.innerHTML = JSON.stringify(data);
    document.head.appendChild(script);

    return () => {
      document.title = 'QuickVideo';
      document.head.removeChild(metaDescription);
      document.head.removeChild(gtmScript);
      document.head.removeChild(script);
    };
  }, []);

  const redirectToSignUp = () => {
    window.open('https://app.quickvideo.ai/signup', '_blank');
  };

  return (
    <div className='conversational'>
      <Parallax speed={10}>
        <div className='conversational_banner'>
          <div style={{marginBottom: window.innerWidth <= 800 ? '50px' : ''}}>
            <h1 style={{color: '#3692DD', textShadow: '1px 0px 4px rgba(50,83,114,0.6)', fontSize: window.innerWidth <= 1000 ? 26 : 36, lineHeight: 1.2, textAlign: window.innerWidth < 768 ? 'center' : ''}}>Build a visual persona with our {window.innerWidth <= 1000 ? '' : <br />} Conversational AI Video Bot </h1>
            <h5 style={{fontWeight: 400, textAlign: window.innerWidth < 768 ? 'center' : ''}}>Your business interactions now have a human touch, fully automated.{window.innerWidth <= 1000 ? '' : <br />} Hold natural conversations, resolve customer queries and so much more!</h5>
            <VuiBox sx={{display: window.innerWidth < 800 ? '' : 'flex', alignItems: 'center', textAlign: 'center'}}>
              <a href="https://calendly.com/quickvideo/demo" target="_blank" rel="noopener noreferrer">
                <Button sx={{background: 'white', color: '#000', borderRadius: '20px', fontWeight: 600, fontSize: 12, fontFamily: '"Plus Jakarta Display", Helvetica, Arial, sans-serif !important', marginTop: 3, '&:hover': {color: "#fff"},}} variant='contained'>
                  <CalendarMonthIcon sx={{marginRight: '5px'}} />
                  Book demo
                  <ArrowOutwardIcon sx={{marginLeft: '3px'}} />
                </Button>
              </a>
              <a href="https://app.quickvideo.ai/signup" target="_blank" rel="noopener noreferrer">
                <Button sx={{background: 'white', color: '#000', borderRadius: '30px', fontWeight: 600, fontSize: 12, fontFamily: '"Plus Jakarta Display", Helvetica, Arial, sans-serif !important', marginTop: 3, '&:hover': {color: "#fff"}, marginLeft: (window.innerWidth < 800 && window.innerWidth > 440) ? 1 : window.innerWidth < 440 ? 0 : 2}} variant='contained'>
                  Start free trial
                  <KeyboardArrowRightIcon />
                </Button>
              </a>
            </VuiBox>
          </div>
          {/* <img src={Banner} alt="banner" height={280} /> */}
          <video height={window.innerWidth <= 1000 ? 'auto' : 280} width={window.innerWidth <= 1000 ? '300px' : ''} muted autoPlay loop style={{marginLeft: '20px'}}>
            <source src='https://quickvideo.blob.core.windows.net/quickvideo/website_media/Conversational Video.mp4' type="video/mp4" />
          </video>
        </div>
      </Parallax>
      <div className="horizontal_line"></div>
      <div className='conversational_personalizedcustomer'>
        <img src='https://quickvideo.blob.core.windows.net/quickvideo/website_media/personalized-customer-service-1.png'alt="personalized customer" width={window.innerWidth <= 1000 ? 330 : 400} loading="lazy" />
        <div style={{marginTop: window.innerWidth <= 800 ? '50px' : '', marginLeft:window.innerWidth <= 1200 ? '15px' : ''}}>
          <h2 style={{fontSize: window.innerWidth <= 1000 ? 26 : '', textAlign: window.innerWidth < 768 ? 'center' : ''}}>Be a Pioneer in Personalized {window.innerWidth <= 800 ? '' : <br />} Customer Service</h2>
          <h5 style={{fontWeight: 400, textAlign: window.innerWidth < 768 ? 'center' : ''}}>Provide a personal experience with an online avatar that can answer {window.innerWidth <= 800 ? '' : <br />} customer questions directly. Break past the limitations of Text-to-Speech {window.innerWidth <= 800 ? '' : <br />} with a human face and realistic speech for a more fulfilling customer experience.</h5>
          <br />
          <h5 style={{fontWeight: 400, textAlign: window.innerWidth < 768 ? 'center' : ''}}>Build a Conversational AI Video bot with QuickVideo and enjoy {window.innerWidth <= 800 ? '' : <br />} an automated solution for your customer service efforts!</h5>
        </div>
      </div>
      <div className="horizontal_line"></div>
      <Parallax speed={10}>
        <div className='conversational_personify'>
          <div>
            <h2 style={{fontSize: window.innerWidth <= 1000 ? 26 : '', textAlign: window.innerWidth < 768 ? 'center' : ''}}>Personify Interactions in a Revolutionary {window.innerWidth <= 800 ? '' : <br />} New Way</h2>
            <h5 style={{fontWeight: 400, textAlign: window.innerWidth < 768 ? 'center' : ''}}>Create an avatar that can answer customer concerns or queries quickly.{window.innerWidth <= 800 ? '' : <br />} QuickVideo offers a whole set of options for customization and languages so{window.innerWidth <= 800 ? '' : <br />} your conversational AI Video bot can converse in any language you desire.</h5>
          </div>
          <img src='https://quickvideo.blob.core.windows.net/quickvideo/website_media/personify-interactions-1.png' alt="personify" width={window.innerWidth <= 1000 ? 320 : 500} loading="lazy" />
        </div>
      </Parallax>
      <div className="horizontal_line"></div>
      <div className='conversational_personalizedcustomer'>
        <img src='https://quickvideo.blob.core.windows.net/quickvideo/website_media/Boost Brand Value-1.png'  alt="boost brand value" width={window.innerWidth <= 1000 ? 320 : 500} style={{marginRight: '15px'}} loading="lazy" />
        <div style={{marginTop: window.innerWidth <= 800 ? '50px' : '', marginLeft: window.innerWidth <= 1200 ? '15px' : ''}}>
          <h2 style={{fontSize: window.innerWidth <= 1000 ? 26 : '', textAlign: window.innerWidth < 768 ? 'center' : ''}}>Boost your Brand Value and Retention</h2>
          <h5 style={{fontWeight: 400, textAlign: window.innerWidth < 768 ? 'center' : ''}}>Stand out from the competition with a unique avatar that aligns {window.innerWidth <= 800 ? '' : <br />} with your brand and helps people recall your company.{window.innerWidth <= 800 ? '' : <br />} QuickVideo helps you create a standout brand image and {window.innerWidth <= 800 ? '' : <br />} garner engagement & retention from customers </h5>
        </div>
      </div>
      <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column'}}>
        {/* <img src='https://quickvideo.blob.core.windows.net/quickvideo/website_media/Text Slide-5.png' alt="textSlide" style={{marginTop: window.innerWidth <= 800 ? '100px' : '150px', marginLeft: 'auto', marginRight: 'auto'}} width={window.innerWidth <= 1000 ? 300 : 1000} loading="lazy" /> */}
        <Button sx={{background: 'white', color: '#000', borderRadius: '30px', fontWeight: 600, fontSize: 12, maxWidth: '200px',fontFamily: '"Plus Jakarta Display", Helvetica, Arial, sans-serif !important', marginTop: 10, '&:hover': {color: "#fff"}, marginLeft: 2}} variant='contained' onClick={redirectToSignUp}>
          Get Started
        </Button>
      </div>
      <div style={{marginTop: '50px'}}>
        <Form />
      </div>
      <noscript>
        <iframe src="https://www.googletagmanager.com/ns.html?id=GTM-MLKZZRFP" height="0" width="0" style="display:none;visibility:hidden"></iframe>
      </noscript>
    </div>
  )
}

export default Conversational;