import React from 'react';
import { Grid, Typography } from '@mui/material';
import './Information.css';
import { Slide, Flip, Zoom } from "react-awesome-reveal"; 
import firstimage from './Image 1.webp';
import secondimage from './Image 2.webp';
import thirdimage from './Image 3.webp';
import one from './01.webp';
import two from './02.webp';
import three from './03.webp';
import firstimageMobile from './mobileImages/image 1.webp';
import secondimageMobile from './mobileImages/image 2.webp';
import thirdimageMobile from './mobileImages/image 3.webp';
import oneMobile from './mobileImages/1.webp';
import twoMobile from './mobileImages/2.webp';
import threeMobile from './mobileImages/3.webp';
import Product from './Product';
import VuiBox from 'components/VuiBox';

const Information = () => {
  return (
    <div className='information' id="information" style={{background: 'linear-gradient(295deg, #110235 48%, #000000 60%)', borderRadius: '60px', paddingTop: '2px'}}>
      <div className='information_heading'>
        <Typography variant='h6' sx={{fontSize: window.innerWidth <= 800 ? 20 : 24, fontWeight: 600, color: 'whitesmoke'}}>HOW IT WORKS</Typography>
        <Typography variant='h5' sx={{fontSize: window.innerWidth <= 800 ? 12 : '', marginTop: 0.5, fontWeight: 600, color: 'whitesmoke'}}>Create Videos in Minutes</Typography>
      </div>
      <VuiBox sx={{marginTop: '25px', justifyContent: 'space-around', overflow: 'hidden', display: 'flex', flexWrap: 'wrap'}}>
          <Product
            image='https://quickvideo.blob.core.windows.net/quickvideo/website_media/01.webp'
            video="https://quickvideo.azureedge.net/quickvideo/working/Screen%20Recording%202024-06-05%20at%2012.mp4"
            title="SignUp and Connect"
            description="Sign up With QuickVideo or login to your registered account"
            audio="false"
          />
          <Product
            image='https://quickvideo.blob.core.windows.net/quickvideo/website_media/02.webp'
            video="https://quickvideo.azureedge.net/quickvideo/website/Screen%20Recording%202024-06-06%20at%205.mp4?updatedAt=1717676601167"
            title="Select Avatar and Video Format"
            description="Select an avatar and voice and start building your video right away or explore any of our several tools and features for video creation!"
            audio="false"
          />
          <Product
            image='https://quickvideo.blob.core.windows.net/quickvideo/website_media/03.webp'
            video="https://quickvideo.azureedge.net/quickvideo/working/final%20video%20part%206%20(1).mp4?updatedAt=1718268040225"
            title="Evaluate and Extract"
            description="Review and import your video, ready for publishing on all your desired channels!"
            audio="true"
          />
      </VuiBox>

    </div>
  )
}

export default Information