import React, { useEffect, useState } from 'react';
import Modal from '@mui/material/Modal';
import { Box, Button, CircularProgress, Typography } from "@mui/material";
import VuiBox from "components/VuiBox";
import pImage from './../../../Pop-up Image 2.webp';
import CloseIcon from '@mui/icons-material/Close';
import FormField from "layouts/applications/wizard/components/FormField";
import VuiButton from "components/VuiButton";
import OtpInput from 'react-otp-input';
import VuiTypography from 'components/VuiTypography';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '60vw',
  bgcolor: '#000',
  border: '2px solid gray',
  borderRadius: '5px'
  // boxShadow: 24,
  // p: 4,
};

const phoneInputStyle = {
  width: window.innerWidth < 768 ? 'auto' : '300px',
  height: window.innerWidth < 768 ? '37px' : '40px',
  fontSize: '14px',
  borderRadius: '15px',
  backgroundColor: '#0f1535',
  border: 'none',
  color: 'whitesmoke'
};


const UserModal = ({openUserModal, setOpenUserModal, handleSubmitUserDetails, name, setName, email, setEmail, number, setNumber, emailError, setEmailError, otp, setOtp, otpSession, setOtpSession, handleOtpCheck, otpLoading, otpError}) => {
  const handleOpen = () => setOpenUserModal(true);
  const handleClose = () => {
    setOpenUserModal(false);
    setOtpSession(true);
  };
  const [phoneError, setPhoneError] = useState('');
  const [resToggle, setResToggle] = useState(false);

  useEffect(() => {
    const handleVisibilityChange = () => {
      if (document.hidden) {
        handleOpen();
      }
    };

    const handleBeforeUnload = (event) => {
      handleOpen();
    };

    window.addEventListener('beforeunload', handleBeforeUnload);
    document.addEventListener('visibilitychange', handleVisibilityChange);

    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityChange);
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, []);

  useEffect(()=>{
    setTimeout(() => {
      setResToggle(false);
      setName('');
      setEmail('');
      // setBusinessSetName('');
      setNumber('');
    }, 1000);
  }, [resToggle])

  const handleName = (e) => {
    setName(e.target.value);
  }

  const handleEmail = (e) => {
    setEmail(e.target.value);
    const emailRegex = /^\S+@\S+\.\S+$/;
    if (!emailRegex.test(e.target.value)) {
      setEmailError("Invalid! please enter a valid email.");
    } else {
      setEmailError("");
    }
  }

  // const handleBusinessName = (e) => {
  //   setBusinessSetName(e.target.value);
  // }

  const handleNumber = (value, country) => {
    setNumber(value);
    const phoneRegex = /^[0-9]{10,}$/;
    if(!phoneRegex.test(value)) {
      setPhoneError("Enter valid phone number");
    } else {
      setPhoneError("");
    }
  }

  return (
    <div>
      <Modal
        open={openUserModal}
        // onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <VuiBox sx={{display: 'flex', justifyContent: 'space-between', flexDirection: window.innerWidth < 1000 ? 'column' : 'row'}}>
            {window.innerWidth > 1000 && <img loading='lazy' src={pImage} alt="pop-up_image" height='600px' style={{flex: 0.4}} />}
            <VuiBox sx={{ flex: '0.6', display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', padding: '5px'}}>
              <CloseIcon color="white" fontSize="medium" sx={{margin: '5px 5px 5px auto', cursor: 'pointer'}} onClick={handleClose} />
              <VuiBox sx={{display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', textAlign: 'center', padding: window.innerWidth < 1000 ? '20px' : ''}}>
                {otpSession ? <VuiBox sx={{display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center'}}>
                  <VuiTypography variant={window.innerWidth < 768 ? 'h5' : 'h4'} color='white' mb='10px' mt='5%' sx={{width: window.innerWidth < 768 ? '150px' : '100%'}}>Kindly check your email for OTP and download the video</VuiTypography>
                  <OtpInput
                    value={otp}
                    onChange={setOtp}
                    numInputs={4}
                    renderSeparator={<span>-</span>}
                    renderInput={(props) => <input {...props} />}
                    className='otp-input'
                    inputStyle={{
                      width: window.innerWidth < 768 ? '30px' : "40px",
                      margin: window.innerWidth < 768 ? 'auto' : "30px",
                      height: "40px",
                      backgroundColor: "white",
                      outline: "none",
                      border: '1px solid skyblue'
                    }}
                  /> 
                  {(otpError && otp !== '') && <h6 style={{color: 'red'}}>{otpError}</h6>}
                  <Button onClick={handleOtpCheck} variant='contained' sx={{background: 'white', color: '#000', marginTop: '10px'}}>{otpLoading ? <CircularProgress size={22} color='white' /> : 'Submit Otp'}</Button>
                </VuiBox>
                : <>
                  <Typography variant="h4" color='whitesmoke' sx={{fontSize: window.innerWidth < 1000 ? '18px' : '24px'}}>Generate Your New Year's Greeting For Free!</Typography>
                  <Typography variant="h6" color='whitesmoke' sx={{fontWeight: 400, fontSize: window.innerWidth < 1000 ? '12px' : '', marginTop: window.innerWidth < 1000 ? '20px' : ''}}>
                    Register yourself to generate your personalized New Year greeting video using QuickVideo.
                  </Typography>
                  <div style={{display: 'flex',alignItems: 'center', flexDirection: 'column', justifyContent: 'space-between', marginTop: '20px'}}>
                    <div style={{display: 'flex',alignItems: 'center', justifyContent: 'space-between', marginBottom: window.innerWidth <= 800 ? '0px' : '20px', flexDirection: 'column'}}>
                      <div style={{width: window.innerWidth<=1000 ? 'auto' : '300px'}}>
                        <FormField type="text" label="full name" placeholder="Eg. Michael" onChange={handleName} value={name} />
                      </div>
                      {/* <div style={{width: window.innerWidth<=1000 ? '150px' : '300px'}}>
                        <FormField type="text" label="Business name" placeholder="Eg. QuickVideo" onChange={handleBusinessName} value={businessName} />
                      </div> */}
                      <div style={{width: window.innerWidth<=1000 ? 'auto' : '300px'}}>
                        <FormField type="text" label="email address" placeholder="Eg. micheal@xyz.com" onChange={handleEmail} value={email} />
                        {(emailError && email !== '') && <h6 style={{color: 'red'}}>{emailError}</h6>}
                      </div>
                      <div style={{width: window.innerWidth<=1000 ? 'auto' : '300px'}}>
                        {/* <FormField type="phone" label="phone number" placeholder="Eg. 9876543210" onChange={handleNumber} value={number} /> */}
                        <VuiTypography variant='h6' color='white' sx={{fontWeight: 400, marginBottom: '5px', marginTop: '5px'}}>What's App Number</VuiTypography>
                        <PhoneInput
                          country={'in'}
                          value={number}
                          onChange={handleNumber}
                          inputStyle={phoneInputStyle}
                        />
                        {(phoneError && number !== '') && <h6 style={{color: 'red'}}>{phoneError}</h6>}
                      </div>
                    </div>
                    <VuiButton onClick={handleSubmitUserDetails} disabled={name === '' || email === '' || number === '' || phoneError !== '' || emailError !== ''} sx={{marginTop: window.innerWidth < 1000 ? '20px' : ''}}>{otpLoading ? <CircularProgress size={22} /> : 'Submit'}</VuiButton>
                  </div>
                </>
                }
              </VuiBox>
            </VuiBox>
          </VuiBox>
        </Box>
      </Modal>
    </div>
  )
}

export default UserModal