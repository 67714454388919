import { Button, CircularProgress } from '@mui/material';
import axios from 'axios';
import VuiBox from 'components/VuiBox'
import VuiTypography from 'components/VuiTypography'
import React, { useEffect, useState } from 'react'
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import UserModal from './UserModal';

const Preview = ({details, captureSnapshot, componentRef, snapshot, bg, res, setRes, loading, setLoading, setPreview, baseUrl}) => {
  const [openUserModal, setOpenUserModal] = useState(false);
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [emailError, setEmailError] = useState('');
  // const [businessName, setBusinessSetName] = useState('');
  const [number, setNumber] = useState('');
  const [otp, setOtp] = useState('');
  const [otpSession, setOtpSession] = useState(false);
  const [otpLoading, setOtpLoading] = useState(false);
  const [otpError, setOtpError] = useState('');

  useEffect(() => {
    captureSnapshot();
    //eslint-disable-next-line
  }, [details.presenter, details.backgroundImage]);

  const showExitModal = async () => {
    await captureSnapshot();
    setOpenUserModal(true);
  }

  const handleSubmitUserDetails = async () => {
    setOtpLoading(true);
    await axios.post(`${baseUrl}/user_info`, {
      name, email, number
    })
    .then(response => {
      console.log(response.data);
      setOtpSession(true);
      setOtpLoading(false);
    })
    .catch(err => {
      console.error(err);
      setEmailError(err.response.data.error);
      setOtpLoading(false);
    });
  }

  const handleOtpCheck = async () => {
    setOtpLoading(true);
    await axios.post(`${baseUrl}/verify_otp`, {
      email, otp
    })
    .then(response => {
      console.log(response.data);
      setOtpSession(false);
      setOpenUserModal(false);
      handleSubmit();
      setOtpLoading(false);
    })
    .catch(err => {
      console.error(err);
      setOtpError(err.response.data.error);
      setOtpLoading(false);
    });
  }

  const handleSubmit = async () => {
    const data = {
      details,
      email,
      snapshot: snapshot,
    };
    
    const headers = {
      accept: 'application/json',
      'content-type': 'multipart/form-data',
    };
    
    const options = {
      method: 'POST',
      url: `${baseUrl}/web_videos`,
      headers: headers,
      data: data
    };
    setLoading(true);
    
    axios(options)
      .then(response => {
        setRes(response.data);
        setLoading(false);
      })
      .catch(error => {
        console.error(error);
        setLoading(false);
      });
  }

  return (
    openUserModal ? 
      <UserModal openUserModal={openUserModal} setOpenUserModal={setOpenUserModal} handleSubmitUserDetails={handleSubmitUserDetails} name={name} setName={setName} email={email} setEmail={setEmail} number={number} setNumber={setNumber} emailError={emailError} setEmailError={setEmailError} otp={otp} setOtp={setOtp} otpSession={otpSession} setOtpSession={setOtpSession} handleOtpCheck={handleOtpCheck} otpLoading={otpLoading} otpError={otpError} />
    :
    loading ? 
      <VuiBox
        bgColor="white"
        borderRadius="50%"
        width="200px"
        height="200px"
        display="flex"
        justifyContent="center"
        alignItems="center"
        color="white"
        m="20% auto"
      >
        <CircularProgress size={30} color="black" />
      </VuiBox>
    : 
      <>
        <ArrowBackIosIcon size={30} color='white' sx={{position: 'absolute', left: window.innerWidth < 768 ? "10%" : '20%', cursor: 'pointer'}} onClick={()=>{
          setPreview(false);
          setRes(null);
        }} />
        {res ? <VuiBox sx={{display: 'flex', flexDirection: 'column', alignItems: 'center', jusitfyContent: 'center', marginTop: '40px'}}>
          <VuiTypography variant={window.innerWidth < 768 ? 'h6' : 'h4'} color='white' textAlign= 'center'>Your video has been generated successfully. <br />You can download the video here. <br />You will also receive an email with video download link.</VuiTypography>
          <video autoPlay controls style={{width: window.innerWidth < 768 ? '300px' : '700px', marginTop: '10px', height: '500px', borderRadius: '10px'}}>
            <source src={res} type="video/mp4" />
          </video>
        </VuiBox> 
        : 
        <VuiBox sx={{display: 'flex', flexDirection: 'column', alignItems: 'center', jusitfyContent: 'center', marginTop: '40px'}}>
          <VuiBox ref={componentRef} sx={{
            width: window.innerWidth < 768 ? "350px" : "700px",
            maxWidth: window.innerWidth < 768 ? "350px" : "700px",
            height: window.innerWidth < 768 ? "200px" : "350px",
            backgroundImage: bg ? `url(${bg})` : 'none',
            marginLeft: "auto",
            marginRight: "auto",
            backgroundPosition: "center",
            backgroundSize: 'cover',
            position: 'relative'
          }}>
            <img
              src={details.presenter}
              loading='lazy'
              alt='presenter'
              width={window.innerWidth < 768 ? "50%" : bg ? "40%" : "50%"}
              height={window.innerWidth < 768 ? "150px" : bg ? "250px" : "350px"}
              style={{
                top: window.innerWidth < 768 ? "25%" : bg ? "29%" :  "",
                position: "absolute", 
                right: window.innerWidth < 768 ? "25%" : bg ? "28.5%" : "25%", 
              }}
            />
            {details.logo && <img
              src={details.logo}
              alt='logo'
              loading='lazy'
              width={window.innerWidth < 768 ? "50px" : "80px"}
              style={{
                bottom: window.innerWidth < 768 ? "10px" : "10px",
                position: "absolute", 
                right: window.innerWidth < 768 ? "10px" : "10px", 
              }}
            />}
          </VuiBox>
          {/* <img
              src={snapshot}
              alt='presenter'
              width={window.innerWidth < 768 ? "50%" : bg ? "45%" : "50%"}
              height={window.innerWidth < 768 ? "160px" : bg ? "250px" : "350px"}
              style={{
                top: window.innerWidth < 768 ? "20%" : bg ? "29%" :  "",
                position: "absolute", 
                right: window.innerWidth < 768 ? "25%" : bg ? "26%" : "25%", 
              }}
            /> */}
          <VuiTypography variant='h5' color='white' mt='20px' style={{width: window.innerWidth < 768 ? "350px" : '600px', textAlign: 'center'}}>
            {details.message}
          </VuiTypography>

          <Button 
            variant='contained'
            onClick={showExitModal}
            disabled={details.message === '' || details.presenter === ''}
            sx={{display: 'flex', justifyContent: 'flex-end', marginTop: '20px' , color: 'whitesmoke', fontSize: window.innerWidth < 768 ? "12px" : '16px', '&:disabled': {background: 'gray', color: '#000'}}}>Create AI generated message</Button>
        </VuiBox>
        }
      </>
  )
}

export default Preview