/* eslint-disable */
import React, { useEffect, useRef, useState } from 'react';
import Dialog from '@mui/material/Dialog';
import './chat.css'
// import Chat from 'layouts/pages/bot-ai/chat';
import VuiBox from 'components/VuiBox';
// import conversationData from 'layouts/pages/conversation-tables/data/conversationData';
import { Button, Card } from '@mui/material';
import MicIcon from '@mui/icons-material/Mic';
import annyang from 'annyang';
import StopIcon from '@mui/icons-material/Stop';
import VuiInput from 'components/VuiInput';
import ClearIcon from '@mui/icons-material/Clear';
import SendIcon from '@mui/icons-material/Send';
import VuiTypography from 'components/VuiTypography';
import typing from './Typing GIF.gif';
import { createStyles, makeStyles } from '@mui/styles';
import { talk } from './streaming-client-api';
import { useVisionUIController } from "context";
import {initializeVideo} from './streaming-client-api';
import axios from 'axios';

const useStyles = makeStyles(() =>
  createStyles({
    messageRow: {
      display: "flex",
    },
    messageRowRight: {
      display: "flex",
      justifyContent: "flex-end",
    },
    messageLightBlue: {
      position: "relative",
      minWidth:"23%",
      maxWidth: "80%",
      padding: "10px",
      backgroundColor: "#0175FF",
      // width: "60%",
      //height: "50px",
      textAlign: "left",
      border: "1px solid #0175FF",
      borderRadius: "10px",
      "&:after": {
        content: "''",
        position: "absolute",
        width: "0",
        height: "0",
        borderTop: "15px solid #0175FF",
        borderLeft: "15px solid transparent",
        borderRight: "15px solid transparent",
        top: "-1px",
        left: "-15px",
      },
      "&:before": {
        content: "''",
        position: "absolute",
        width: "0",
        height: "0",
        borderTop: "17px solid #0175FF",
        borderLeft: "16px solid transparent",
        borderRight: "16px solid transparent",
        top: "-1px",
        left: "-17px",
      },
    },

    messageContent: {
      padding: 0,
      margin: 0,
      color: "#fff",
      font: "Plus Jakarta Display",
    },
    messageTimeStampLeft: {
      fontSize: "0.75em",
      textAlign: "left",
      paddingTop: "5px",
      color: "#bfbfbf",
    },
    messageTimeStampRight: {
      fontSize: "0.75em",
      color: "#bfbfbf",
      textAlign: "right",
      paddingTop: "5px",
    },
    lightBlue: {
      color: "#FFF",
      width: "150%",
    },
    displayName: {
      marginLeft: "20px",
    },
  })
);

const useRightStyles = makeStyles(() =>
  createStyles({
    messageRow: {
      display: "flex",
    },
    messageRowRight: {
      display: "flex",
      justifyContent: "flex-end",
    },
    messageLightBlue: {
      position: "relative",
      minWidth:"23%",
      maxWidth: "47.5%",
      padding: "10px",
      backgroundColor: "#0175FF",
      // width: "60%",
      //height: "50px",
      textAlign: "left",
      font: "200 .8em 'Plus Jakarta Display', sans-serif",
      border: "1px solid #0175FF",
      borderRadius: "10px",
      "&:after": {
        content: "''",
        position: "absolute",
        width: "0",
        height: "0",
        borderTop: "15px solid #0175FF",
        borderLeft: "15px solid transparent",
        borderRight: "15px solid transparent",
        top: "-1px",
        left: "-15px",
      },
      "&:before": {
        content: "''",
        position: "absolute",
        width: "0",
        height: "0",
        borderTop: "17px solid #0175FF",
        borderLeft: "16px solid transparent",
        borderRight: "16px solid transparent",
        top: "-1px",
        left: "-17px",
      },
    },
    messageDeepBlue: {
      // right: "3%",
      position: "relative",
      alignSelf: "right",
      maxWidth: "60%",
      padding: "10px",
      backgroundColor: "transparent",
      //height: "50px",
      textAlign: "left",
      border: "1px solid #0175FF",
      borderRadius: "10px",
      "&:after": {
        content: "''",
        position: "absolute",
        width: "0",
        height: "0",
        borderTop: "15px solid transparent",
        borderLeft: "15px solid transparent",
        borderRight: "15px solid transparent",
        top: "0",
        right: "-15px",
      },
      "&:before": {
        content: "''",
        position: "absolute",
        width: "0",
        height: "0",
        borderTop: "17px solid #0175FF",
        borderLeft: "17px solid transparent",
        borderRight: "16px solid transparent",
        top: "-1px",
        right: "-17px",
      },
    },

    messageContent: {
      padding: 0,
      margin: 0,
      color: "#fff",
      font: "Plus Jakarta Display",
    },
    messageTimeStampLeft: {
      fontSize: "0.75em",
      textAlign: "left",
      paddingTop: "5px",
      color: "#bfbfbf",
    },
    messageTimeStampRight: {
      fontSize: "0.75em",
      color: "#bfbfbf",
      textAlign: "right",
      paddingTop: "5px",
    },
    deepBlue: {
      color: "#FFF",
      width: "150%",
    },
    lightBlue: {
      color: "#FFF",
      width: "150%",
    },
    displayName: {
      marginLeft: "20px",
    },
  })
);

const ChatWindow = ({ open, position, onClose }) => {
  //eslint-disable-next-line
  const suggestions = ['text to video', 'video personalization', 'conversational video chatbot', 'Schedule a call'];
  // const texttovideo = ['redirect to page', 'back to the menu']
  const [isListening, setIsListening] = useState(false);
  const [text, setText] = useState('');
  const videos =['https://quickvideo.blob.core.windows.net/quickvideo/generated_videos/8cd_welcome.mp4', 'https://quickvideo.blob.core.windows.net/quickvideo/generated_videos/55a_first.mp4', 'https://quickvideo.blob.core.windows.net/quickvideo/generated_videos/ad3_texttovideo.mp4', 'https://quickvideo.blob.core.windows.net/quickvideo/generated_videos/0ca_personalized.mp4', 'https://quickvideo.blob.core.windows.net/quickvideo/generated_videos/250_conersational.mp4', 'https://quickvideo.blob.core.windows.net/quickvideo/generated_videos/a8e_contact.mp4', 'https://quickvideo.blob.core.windows.net/quickvideo/generated_videos/1c8_thanks.mp4', 'https://quickvideo.blob.core.windows.net/quickvideo/generated_videos/941_sorry.mp4'];
  const [videoIndex, setVideoIndex] = useState(0);
  const [messages, setMessages] = useState([{message: 'Welcome to QuickVideo, How can i assist you today ?',type: 'incoming'}]);
  const [isLoading, setIsLoading] = useState(false);
  const [isVideoLoaded, setIsVideoLoaded] = useState(false);
  const messagesContainerRef = useRef(null);

  const videoRef = useRef();
  
  useEffect(() => {
    if (videoRef.current) {
      setIsVideoLoaded(false);

      videoRef.current.load();
      videoRef.current.play().then(() => {
        setIsVideoLoaded(true);
      });
    }
  }, []);

  useEffect(() => {
    let voice = "en-US-DavisNeural"
    let idleVideo = 'https://clips-presenters.d-id.com/william/qE4P1OFtWM/Lc2cQ93AYR/preview.mp4'
    initializeVideo(videoRef, idleVideo, voice);
    talk(messages[messages.length-1].message);
  }, [])

  const makeAPIRequest = async (prompt) => {
    try {
      const res = await axios.post('https://quick.quickvideo.ai/generate_ai_text', {
        prompt_text: prompt
      })
      
      const newAvailableText = res.data.generated_text;
      return newAvailableText.trim();
    } catch (err) {
      console.log(err);
    }
  }

  const generateReply = async (input) => {
    const reply = await makeAPIRequest(input);
    return reply;
    // if (!isNaN(input)) {
    //   setVideoIndex(6);
    //   return 'thanks for providing, we will get back to you soon.';
    // }

    // const text = input.toLowerCase();

    // switch (text) {
    //   case 'hi':
    //     setVideoIndex(1);
    //     return suggestions;

    //   case 'hey':
    //     setVideoIndex(1);
    //     return suggestions;

    //   case 'hello':
    //     setVideoIndex(1);
    //     return suggestions;

    //   case 'text to video':
    //     setVideoIndex(2);
    //     return 'It is a smart Text-to-Video generator tool that simplifies the creation of personalized and professional Ai-generated Videos for a better customer experience.';

    //   case 'video personalization':
    //     setVideoIndex(3);
    //     return 'Create and share personalized videos with a human-like element to encourage the receiver to engage.';

    //   case 'conversational video chatbot':
    //     setVideoIndex(4);
    //     return 'Your business interactions now have a human touch, fully automated. Hold natural conversations, resolve customer queries and so much more!';

    //   case 'schedule a call':
    //     setVideoIndex(5);
    //     return 'may i know your contact details ?';

    //   case 'show me menu':
    //     setVideoIndex(1);
    //     return suggestions;
      
    //   case 'show me menu again please':
    //     setVideoIndex(1);
    //     return suggestions;
    
    //   default:
    //     setVideoIndex(7);
    //     return 'Sorry, I am not getting your query.';
    // }
  };
  
  const sendMessage = async () => {
    const newOutgoingMessage = { message: text, type: 'outgoing' };
    const newMessages = [...messages, newOutgoingMessage];
    text !== '' && setMessages(newMessages);

    setText('');
    setIsLoading(true);

    let reply = await generateReply(text.toLowerCase());
    await talk(reply);
    setTimeout(() => {
      const newReceivedMessage = { message: reply, type: 'incoming' };
      setMessages([...newMessages, newReceivedMessage]);
      setIsLoading(false);
      if (messagesContainerRef.current) {
        messagesContainerRef.current.scrollTop = messagesContainerRef.current.scrollHeight;
      }
    }, 4000);
  };

  useEffect(() => {
    if (messagesContainerRef.current) {
      messagesContainerRef.current.scrollTop = messagesContainerRef.current.scrollHeight;
    }
  }, [messages])
  

  const handleButton = (index, item) => {
    setText(item);
  }

  const dialogStyle = {
    position: 'fixed',
    bottom:  window.innerWidth < 768 ? '70px' : position.bottom,
    right:  window.innerWidth < 768 ? 'auto' : position.right,
    zIndex: 1001,
    // background: 'linear-gradient(127.09deg, rgba(6, 11, 40, 0.94) 19.41%, rgba(10, 14, 35, 0.49) 76.65%)',
    background: 'rgb(8,8,54)',
    transition: 'right 250ms cubic-bezier(0.4, 0, 0.6, 1) 0ms',
    width:  window.innerWidth < 768 ? 300 : 360,
    minHeight:  window.innerWidth < 768 ? 300 : 450,
    height:  window.innerWidth < 768 ? 450 : 600,
    border: '1px solid skyblue',
    borderBottom: 'none'
  };

  useEffect(() => {
    annyang.addCallback('result', handleResult);

    return () => {
      annyang.removeCallback('result', handleResult);
    };
  }, []);

  const handleResult = (phrases) => {
    setText(phrases[0]);
  };

  // const toggleRecognition = () => {
  //   if (isListening) {
  //     setIsListening(false); 
  //     annyang.abort();
  //   } else {
  //     setIsListening(true);
  //     annyang.start();
  //   }
  // };
  const startRecognition = () => {
    setIsListening(true);
    annyang.start();
    // const newTimeoutId = setTimeout(stopRecognition, 3000);
    // setTimeoutId(newTimeoutId);
  };

  const stopRecognition = () => {
    setIsListening(false);
    annyang.abort();
    // clearTimeout(timeoutId);
  };

  const classes = useStyles();
  const rightClasses = useRightStyles();
  
  return (
    <Dialog
      open={open}
      onClose={onClose}
      PaperProps={{ style: dialogStyle }}
      className="chat-window"
    >
      <div style={{display: 'flex', justifyContent: 'flex-end', alignItems: 'center', padding: '10px 10px 0', background: 'rgb(8,8,54)', cursor: 'pointer'}} onClick={onClose}>
        <ClearIcon size={18} color='white' />
      </div>
      <Card style={{display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'space-between', background: 'rgb(8,8,54)', paddingTop: '0px'}}>
        <VuiBox
          style={{
            backgroundSize: "contain",
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-around",
            alignItems: "center",
            backgroundPosition: 'center',
            position: "relative",
            objectFit: "contain",
            // margin: "auto",
            padding: '0 5px',
            paddingTop: 0
          }}
        >
          <VuiTypography style={{color: 'whitesmoke'}}>Williams from QuickVideo</VuiTypography>
          <div className={`video-container ${isVideoLoaded ? 'loaded' : ''}`}>
            <video ref={videoRef} id="my-video" playsInline autoPlay>
              <source src='https://clips-presenters.d-id.com/william/qE4P1OFtWM/Lc2cQ93AYR/preview.mp4' type="video/mp4" />
            </video>
          </div>
        </VuiBox>
        
        {/* <Chat conversation={activeConversation.msg_data} /> */}
        {/* {inputSuggestion && <VuiInput value={inputSuggestion} />}
        {(!inputSuggestion && transcription) && <VuiInput multiline minRows={2} maxRows={5} sx={{textAlign: 'center'}} value={transcription} />} */}
      </Card>
      <div style={{display: 'flex', alignItems: 'center', justifyContent: 'space-evenly', marginTop: 'auto', marginBottom: '25px', flexDirection: 'column'}}>
        <div style={{maxHeight: window.innerWidth < 768 ? 150 : 220, overflowY: 'auto', width: '100%', padding: '5px 25px'}} ref={messagesContainerRef}>
          {messages && messages.map(({message, type}, index) => (
            type === 'incoming' ? 
              Array.isArray(message) ? 
              <div key={index} style={{color: 'whitesmoke', marginBottom: 25, marginRight: 'auto', padding: '5px 10px'}}>
                {message.map((item, index) => (
                  <Button variant='contained' key={index} sx={{width: '100px', color: 'whitesmoke', marginRight: '5px', marginBottom: '5px'}} onClick={()=>handleButton(index, item)}>{item}</Button>
                ))}
              </div> 
              : 
              <div key={index} className={classes.messageLightBlue} style={{color: 'whitesmoke', marginBottom: 25, marginRight: 'auto', padding: '5px 10px'}}>
                <h6 style={{fontSize: window.innerWidth < 768 ? '12px' : ''}}>{message}</h6>
              </div>
            :
            <div key={index} className={rightClasses.messageDeepBlue} style={{color: 'whitesmoke', marginBottom: 15, marginLeft: 'auto', padding: '5px 10px',}}>
              <h6 style={{fontSize: window.innerWidth < 768 ? '12px' : ''}}>{message}</h6>
            </div>
          ))}
          {isLoading && <img loading='lazy' src={typing} alt="typing" width={50} />}
        </div>
        <div style={{display:'flex', alignItems: 'center', justifyContent: 'space-between'}}>
          <VuiInput sx={{width: window.innerWidth < 768 ? 'auto !important' : '200px !important', marginRight: '5px'}} value={text} onChange={e => {setText(e.target.value)}} onKeyDown={(e) => {if (e.key === 'Enter') {e.preventDefault(); sendMessage(); }}} />
          <SendIcon size={16} color="white" style={{margin: '0px 5px', cursor: 'pointer'}} onClick={sendMessage} />
          {isListening ? 
            <Button variant='contained' sx={{margin: '0 5px'}} onClick={stopRecognition}>
             <StopIcon size={22} color='white' /> 
            </Button>
            :  
            <Button variant='contained' sx={{margin: '0 5px'}} onClick={startRecognition}>
             <MicIcon size={22} color='white' /> 
            </Button>
          }
        </div>
      </div>
    </Dialog>
  );
};

export default ChatWindow;
