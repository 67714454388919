import React, { useEffect, useState } from 'react';
import About from "layouts/applications/wizard/components/About";
import { Card } from '@mui/material';
import VuiBox from 'components/VuiBox';
import axios from 'axios';
import { Email } from 'smtpjs';

const Contactus = () => {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [businessName, setBusinessSetName] = useState('');
  const [number, setNumber] = useState('');
  const [res, setRes] = useState('');
  const [resToggle, setResToggle] = useState(false);
  const [loader, setLoader] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    document.title = 'Contact Us at QuickVideo';

    const metaDescription = document.createElement('meta');
    metaDescription.name = 'description';
    metaDescription.content = 'Get in touch with QuickVideo for professional video generation. Contact us today to bring your ideas to life.';
    document.head.appendChild(metaDescription);

    const gtmScript = document.createElement('script');
    gtmScript.innerHTML = `
      (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start': new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0], j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src= 'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);})(window,document,'script','dataLayer','GTM-MLKZZRFP');
    `;
    document.head.appendChild(gtmScript);

    return () => {
      document.title = 'QuickVideo';
      document.head.removeChild(metaDescription);
      document.head.removeChild(gtmScript);
    };
  }, []);

  const postDetails = async () => {
    setLoader(true);
    await axios.post('https://quick.quickvideo.ai/web_leads', {
      full_name: name,
      email_id: email,
      business_name: businessName,
      mobile: number
    })
    .then((res) => {
      console.log(res.data);
      setRes(res.data.message);
      setResToggle(true);
      setLoader(false);
    })
    .catch(err => {
      console.log(err);
      setLoader(false);
    })
  }

  useEffect(()=>{
    setTimeout(() => {
      setResToggle(false);
      setRes('');
      setName('');
      setEmail('');
      setBusinessSetName('');
      setNumber('');
    }, 3000);
  }, [resToggle])

  return (
    <Card sx={{margin: window.innerWidth <= 800 ? 5 : 10}}>
      <VuiBox p={window.innerWidth<=800 ? 0 : 3} mx={window.innerWidth<=800 ? 0 : 10} my={window.innerWidth<=800 ? 0 : 1}>
        <About 
          name={name}
          setName={setName}
          email={email}
          setEmail={setEmail}
          businessName={businessName}
          setBusinessSetName={setBusinessSetName}
          number={number}
          setNumber={setNumber}
          postDetails={postDetails}
          res={res}
          loader={loader}
          resToggle={resToggle}
        />
      </VuiBox>
      <noscript>
        <iframe src="https://www.googletagmanager.com/ns.html?id=GTM-MLKZZRFP" height="0" width="0" style="display:none;visibility:hidden"></iframe>
      </noscript>
    </Card>
  )
}

export default Contactus