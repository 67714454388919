import React, { useEffect } from 'react';
import bannerVideo from './videos/Video Personalisation.mp4';
import firstTextSlide from './images/personalized/Text Slide1.png';
import secondTextSlide from './images/personalized/Text Slide1-1.png';
import { Button } from '@mui/material';
import firstIcon from './images/personalized/Data Collection.webp';
import secondIcon from './images/personalized/Data Processing.webp';
import thirdIcon from './images/personalized/Content Customisation.webp';
import fourthIcon from './images/personalized/Data Collection.webp';
import fifthIcon from './images/personalized/Delivery.webp';
import { Slide, Flip, Zoom, Fade } from "react-awesome-reveal";
import VerticalCarousel from './VerticalCarousel';
import UseSlides from './UseSlides';
import ArrowOutwardIcon from '@mui/icons-material/ArrowOutward';
import VuiBox from 'components/VuiBox';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import Form from '../Form/Form';

const Personalization = () => {
  const items = ['Landing Pages', 'Email Marketing', 'Event Invitations', 'Social Media', 'Products Recommendations', 'Training & Learning', 'Customer Support', 'Remarketing ', 'Festival Celebrations & Milestones', 'Personalized Thank you Messages'];

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    document.title = 'Personalized AI Video Generator | QuickVideo';

    const metaDescription = document.createElement('meta');
    metaDescription.name = 'description';
    metaDescription.content = 'Discover the power of a Personalized AI video Generator. Enhance your marketing strategy with this innovative technology.';
    document.head.appendChild(metaDescription);

    const gtmScript = document.createElement('script');
    gtmScript.innerHTML = `
      (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start': new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0], j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src= 'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);})(window,document,'script','dataLayer','GTM-MLKZZRFP');
    `;
    document.head.appendChild(gtmScript);

    return () => {
      document.title = 'QuickVideo';
      document.head.removeChild(metaDescription);
      document.head.removeChild(gtmScript);
    };
  }, []);

  const redirectToSignUp = () => {
    window.open('https://app.quickvideo.ai/signup', '_blank');
  };

  return (
    <div className='personalization'>
      <div className="personalization_banner">
        <div style={{marginBottom: window.innerWidth <= 800 ? '50px' : ''}}>
          <h1 style={{color: '#3692DD', textShadow: '1px 0px 4px rgba(50,83,114,0.6)', fontSize: window.innerWidth <= 1000 ? 26 : 36, lineHeight: 1.2, textAlign: window.innerWidth < 768 ? 'center' : ''}}>Personalized AI Video Generator {window.innerWidth <= 1000 ? '' : <br />} to promote conversions</h1>
          <h5 style={{fontWeight: 400, textAlign: window.innerWidth < 768 ? 'center' : ''}}>Create and share personalized videos with a human-like {window.innerWidth <= 1000 ? '' : <br />} element to encourage the receiver to engage.</h5>
          <VuiBox sx={{display: window.innerWidth < 800 ? '' : 'flex', alignItems: 'center', textAlign: 'center'}}>
            <a href="https://calendly.com/quickvideo/demo" target="_blank" rel="noopener noreferrer">
              <Button sx={{background: 'white', color: '#000', borderRadius: '20px', fontWeight: 600, fontSize: 12, fontFamily: '"Plus Jakarta Display", Helvetica, Arial, sans-serif !important', marginTop: 3, '&:hover': {color: "#fff"},}} variant='contained'>
                <CalendarMonthIcon sx={{marginRight: '5px'}} />
                Book demo
                <ArrowOutwardIcon sx={{marginLeft: '3px'}} />
              </Button>
            </a>
            <a href="https://app.quickvideo.ai/signup" target="_blank" rel="noopener noreferrer">
              <Button sx={{background: 'white', color: '#000', borderRadius: '30px', fontWeight: 600, fontSize: 12, fontFamily: '"Plus Jakarta Display", Helvetica, Arial, sans-serif !important', marginTop: 3, '&:hover': {color: "#fff"}, marginLeft: (window.innerWidth < 800 && window.innerWidth > 440) ? 1 : window.innerWidth < 440 ? 0 : 2}} variant='contained'>
                Start free trial
                <KeyboardArrowRightIcon />
              </Button>
            </a>
          </VuiBox>
        </div>
        {/* <img src={banner} alt="banner" width={600} /> */}
        <video height={window.innerWidth <= 1000 ? 'auto' : 350}  width={window.innerWidth <= 1000 ? '300px' : ''} muted autoPlay loop>
          <source src={bannerVideo} type="video/mp4" />
        </video>
      </div>
      <img src='https://quickvideo.blob.core.windows.net/quickvideo/website_media/Text%20Slide1.png' alt="firsttextslide" width={window.innerWidth <= 1000 ? 280 : 1000} style={{marginTop:window.innerWidth <= 800 ? '50px' : '150px', marginLeft: window.innerWidth < 768 ? '' : 'auto', marginRight: window.innerWidth < 768 ? '' : 'auto'}} loading="lazy" />
      {/* <UseSlides /> */}
      <div style={{display: 'flex', flexDirection: 'column', padding: window.innerWidth <= 1000 ? '40px 5px' : '50px 100px', flexWrap:'wrap', marginTop: '50px'}}>
        <h3 style={{marginLeft: 'auto', marginRight: 'auto', marginBottom: '50px', textAlign: window.innerWidth < 768 ? 'center' : ''}}>Steps for Personalization</h3>
        <div style={{display: 'flex', alignItems: 'center', justifyContent: 'flex-start', marginBottom: '20px', flexDirection: window.innerWidth <= 800 ? 'column' : ''}}>
          <Fade delay={500}>
            <img src='https://quickvideo.blob.core.windows.net/quickvideo/website_media/Data Collection.webp' alt="firstIcon" width={window.innerWidth <= 800 ? 200 : 450} loading="lazy" />
          </Fade>
          <Fade>
            <div style={{display: 'flex', flexDirection: 'column', alignItems: 'space-between', marginLeft: window.innerWidth <= 800 ? 10 : 20}}>
              <p style={{fontSize: window.innerWidth <= 800 ? 16 : 22, fontWeight: 600, textAlign: window.innerWidth < 768 ? 'center' : ''}}>User Data Collection</p>
              <p style={{fontSize: window.innerWidth <= 800 ? 12 : 14, fontWeight: 400, textAlign: window.innerWidth < 768 ? 'center' : ''}}>The first step is to gather relevant data about the receiver.{window.innerWidth <= 800 ? '' : <br />} This can include basic information like the viewer's name, location,{window.innerWidth <= 800 ? '' : <br />}or other details, as well as more specific data related to their interests.</p>
            </div>
          </Fade>
        </div>
        <div style={{display: 'flex', alignItems: 'center', justifyContent: 'flex-end', marginBottom: '20px', flexDirection: window.innerWidth <= 800 ? 'column' : ''}}>
          {window.innerWidth <= 800 && <Fade delay={300}>
            <img src='https://quickvideo.blob.core.windows.net/quickvideo/website_media/Data Processing.webp' alt="secondIcon" width={window.innerWidth <= 800 ? 200 : 450} loading="lazy" />
          </Fade>}
          <Fade>
            <div style={{display: 'flex', flexDirection: 'column', alignItems: 'space-between', marginLeft: window.innerWidth <= 800 ? 10 : 20}}>
              <p style={{fontSize: window.innerWidth <= 800 ? 16 : 22, fontWeight: 600, textAlign: 'right', textAlign: window.innerWidth < 768 ? 'center' : ''}}>Data Processing</p>
              <p style={{fontSize: window.innerWidth <= 800 ? 12 : 14, fontWeight: 400, textAlign: 'right', textAlign: window.innerWidth < 768 ? 'center' : ''}}>The collected data is then processed and analyzed by Quickvideo’s {window.innerWidth <= 800 ? '' : <br />} proprietary Scraper Models. This may involve using machine learning{window.innerWidth <= 800 ? '' : <br />} algorithms to identify patterns and variables.</p>
            </div>
          </Fade>
          {window.innerWidth >= 800 && <Fade delay={300}>
            <img src='https://quickvideo.blob.core.windows.net/quickvideo/website_media/Data Processing.webp' alt="secondIcon" width={window.innerWidth <= 800 ? 200 : 450} loading="lazy" />
          </Fade>}
        </div>
        <div style={{display: 'flex', alignItems: 'center', justifyContent: 'flex-start', marginBottom: '20px', flexDirection: window.innerWidth <= 800 ? 'column' : ''}}>
          <Fade delay={500}>
            <img src='https://quickvideo.blob.core.windows.net/quickvideo/website_media/Content Customisation.webp' alt="thirdicon" width={window.innerWidth <= 800 ? 200 : 450} loading="lazy" />
          </Fade>
          <Fade>
            <div style={{display: 'flex', flexDirection: 'column', alignItems: 'space-between', marginLeft: window.innerWidth <= 800 ? 10 : 20}}>
              <p style={{fontSize: window.innerWidth <= 800 ? 16 : 22, fontWeight: 600, textAlign: window.innerWidth < 768 ? 'center' : ''}}>Content Customization</p>
              <p style={{fontSize: window.innerWidth <= 800 ? 12 : 14, fontWeight: 400, textAlign: window.innerWidth < 768 ? 'center' : ''}}>The AI then uses the processed data to customize various elements{window.innerWidth <= 800 ? '' : <br />} of the video content. This customization can range from simple mention {window.innerWidth <= 800 ? '' : <br />} of the user’s name to inserting dynamic products based on their interest.</p>
            </div>
          </Fade>
        </div>
        <div style={{display: 'flex', alignItems: 'center', justifyContent: 'flex-end', marginBottom: '20px', flexDirection: window.innerWidth <= 800 ? 'column' : ''}}>
          {window.innerWidth <= 800 && <Fade delay={300}>
            <img src='https://quickvideo.blob.core.windows.net/quickvideo/website_media/Dynamic Rendering.webp' alt="fourthIcon" width={window.innerWidth <= 800 ? 200 : 450} loading="lazy" />
          </Fade>}
          <Fade>
            <div style={{display: 'flex', flexDirection: 'column', alignItems: 'space-between', marginLeft: window.innerWidth <= 800 ? 10 : 20}}>
              <p style={{fontSize: window.innerWidth <= 800 ? 16 : 22, fontWeight: 600, textAlign: window.innerWidth < 768 ? 'center' : 'right'}}>Dynamic Rendering</p>
              <p style={{fontSize: window.innerWidth <= 800 ? 12 : 14, fontWeight: 400, textAlign: window.innerWidth < 768 ? 'center' : 'right'}}>The AI dynamically generates a personalized video for each viewer, {window.innerWidth <= 800 ? '' : <br />} incorporating the customized elements. </p>
            </div>
          </Fade>
          {window.innerWidth >= 800 && <Fade delay={300}>
            <img src='https://quickvideo.blob.core.windows.net/quickvideo/website_media/Dynamic Rendering.webp' alt="secondIcon" width={window.innerWidth <= 800 ? 200 : 450} loading="lazy" />
          </Fade>}
        </div>
        <div style={{display: 'flex', alignItems: 'center', justifyContent: 'flex-start', marginBottom: '20px', flexDirection: window.innerWidth <= 800 ? 'column' : ''}}>
          <Fade delay={500}>
            <img src='https://quickvideo.blob.core.windows.net/quickvideo/website_media/Delivery.webp' alt="fifthicon" width={window.innerWidth <= 800 ? 200 : 450} loading="lazy" />
          </Fade>
          <Fade>
            <div style={{display: 'flex', flexDirection: 'column', alignItems: 'space-between', marginLeft: window.innerWidth <= 800 ? 10 : 20}}>
              <p style={{fontSize: window.innerWidth <= 800 ? 16 : 22, fontWeight: 600, textAlign: window.innerWidth < 768 ? 'center' : ''}}>Delivery</p>
              <p style={{fontSize: window.innerWidth <= 800 ? 12 : 14, fontWeight: 400, textAlign: window.innerWidth < 768 ? 'center' : ''}}>That’s it! All you need to do now is sit back and let the AI do the talking.</p>
            </div>
          </Fade>
        </div>
      </div>
      {/* <div className="horizontal_line"></div> */}
      {/* <VerticalCarousel data={items} leadingText='Your Personalized Videos can be used in' /> */}
      <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column'}}>
        <img src='https://quickvideo.blob.core.windows.net/quickvideo/website_media/Text Slide1-1.png' alt="secondtextslide" width={window.innerWidth <= 1000 ? 300 : 1000} style={{marginTop:window.innerWidth <= 1000 ? '50px' : '150px', marginLeft: 'auto', marginRight: 'auto'}} loading="lazy" />
        <Button sx={{background: 'white', color: '#000', borderRadius: '30px', fontWeight: 600, fontSize: 12, maxWidth: '200px', fontFamily: '"Plus Jakarta Display", Helvetica, Arial, sans-serif !important', marginTop: 3, '&:hover': {color: "#fff"}, marginLeft: 2}} variant='contained' onClick={redirectToSignUp}>
          Get Started
        </Button>
      </div>
      <div style={{marginTop: '50px'}}>
        <Form />
      </div>
      <noscript>
        <iframe src="https://www.googletagmanager.com/ns.html?id=GTM-MLKZZRFP" height="0" width="0" style="display:none;visibility:hidden"></iframe>
      </noscript>
    </div>
  )
}

export default Personalization