/** 

=========================================================
* Vision UI PRO React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/vision-ui-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com/)

* Design and Coded by Simmmple & Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Visionware.

*/

import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import App from "App";

// Vision UI Context Provider
import { VisionUIControllerProvider } from "context";
import { ParallaxProvider } from 'react-scroll-parallax';

ReactDOM.render(
  <BrowserRouter>
    <VisionUIControllerProvider>
      <ParallaxProvider>
        <App />
      </ParallaxProvider>
    </VisionUIControllerProvider>
  </BrowserRouter>,
  document.getElementById("root")
);
// const rootElement = document.getElementById("root");

// if (rootElement.hasChildNodes()) {
//   ReactDOM.hydrate(
//     <BrowserRouter>
//       <VisionUIControllerProvider>
//         <ParallaxProvider>
//           <App />
//         </ParallaxProvider>
//       </VisionUIControllerProvider>
//     </BrowserRouter>,
//   rootElement);
// } else {
//   ReactDOM.render(
//     <BrowserRouter>
//       <VisionUIControllerProvider>
//         <ParallaxProvider>
//           <App />
//         </ParallaxProvider>
//       </VisionUIControllerProvider>
//     </BrowserRouter>,
//   rootElement);
// }
