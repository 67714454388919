import { Button, Typography } from '@mui/material';
import VuiBox from 'components/VuiBox';
import React, { useState } from 'react';
import './Information.css';
import { NavLink } from 'react-router-dom/cjs/react-router-dom.min';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import VolumeOffIcon from '@mui/icons-material/VolumeOff';
import VolumeUpIcon from '@mui/icons-material/VolumeUp';
import { Tooltip } from '@mui/material';
import { Zoom } from 'react-awesome-reveal';

const Product = ({image, title, description, buttonName, redirect, video, audio}) => {
  const [muted, setMuted] = useState(true);

  const toggleMute = () => {
    setMuted(prevMuted => !prevMuted);
  };

  return (
    <VuiBox sx={{display: "flex", alignItems: 'center', justiftContent: 'space-between', padding: '10px', flexDirection: 'column', textAlign: 'center', maxHeight: '80vh', maxWidth: '400px', position: 'relative'}}>
      <Zoom triggerOnce>
        <img src={image} alt='product' style={{borderRadius: '15px'}} width={window.innerWidth <= 800 ? 100: 150} height={window.innerWidth <= 800 ? 50: 100} loading="lazy" />
      </Zoom>
      <video width='100%' height={350} style={{flex: 0.6, borderRadius: '20px', marginTop: '-10px'}} autoPlay muted={muted} loop playsInline>
        <source src={video} type="video/mp4" />
      </video>
      {audio === "true" && <button
        onClick={toggleMute}
        style={{
          position: 'absolute',
          top: '270px',
          left: '20px',
          background: 'transparent',
          border: 'none',
          cursor: 'pointer',
          color: 'white',
          fontSize: '16px'
        }}
      >
        {muted ? 
          <Tooltip title='Unmute' placement='right'>
            <VolumeOffIcon color='black' fontSize={window.innerWidth < 800 ? 'small' : 'large'} />
          </Tooltip> 
          : 
          <Tooltip title='Mute' placement='right'>
            <VolumeUpIcon color='black' fontSize={window.innerWidth < 800 ? 'small' : 'large'} />
          </Tooltip>}
      </button>}
      {/* <img src={gif} alt='product' style={{flex: 0.6, borderRadius: '20px', marginTop: '-10px'}} width={window.innerWidth <= 800 ? 100: 150} height={window.innerWidth <= 800 ? 50: 150} loading="lazy" /> */}
      <Typography variant='h5' color='whitesmoke' sx={{marginTop: '20px'}}>{title}</Typography>
      <Typography variant='h6' sx={{marginTop: '10px', color: 'whitesmoke', fontWeight: 300, marginBottom: '5px', height: window.innerWidth <=1000 ? "auto" : '200px', overflow: window.innerWidth < 1000 ? 'auto' : ''}}>{description}</Typography>
      {/* <NavLink to={`/${redirect}`}>
        <Button sx={{background: 'white', color: '#000', borderRadius: '20px', fontWeight: 600, fontSize: 12, fontFamily: '"Plus Jakarta Display", Helvetica, Arial, sans-serif !important', marginTop: window.innerWidth <= 1000 ? '0px' : '20px', '&:hover': {color: "#fff"},}} variant='contained' style={{marginBottom: window.innerWidth <= 800 ? '30px' : ''}}>
        {buttonName}
        <KeyboardArrowRightIcon />
        </Button>
      </NavLink> */}
    </VuiBox>
  )
}

export default Product