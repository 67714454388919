import Card from "@mui/material/Card";
// import Divider from "@mui/material/Divider";

import { useEffect, useState } from "react";

// Vision UI Dashboard PRO React components
// import VuiBox from "components/VuiBox";
// import VuiTypography from "components/VuiTypography";
// import VuiBadge from "components/VuiBadge";
import { CircularProgress, Container, Grid } from "@mui/material";
// import VuiButton from "components/VuiButton";
// import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import axios from "axios";
import About from "layouts/applications/wizard/components/About";
import Header from "layouts/pages/pricing-page/components/Header";
import PricingCards from "layouts/pages/pricing-page/components/PricingCards";

function Premium() {
  const [currentPlan, setCurrentPlan] = useState(false);
  const [personalPlan, setPersonalPlan] = useState(false);
  const [businessPlan, setBusinessPlan] = useState(false);
  const [currentPlanYearly, setCurrentPlanYearly] = useState(false);
  const [personalPlanYearly, setPersonalPlanYearly] = useState(false);
  const [businessPlanYearly, setBusinessPlanYearly] = useState(false);
  const [enterprisePlan, setEnterprisePlan] = useState(false);
  const [showForm, setShowForm] = useState(false);
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [businessName, setBusinessSetName] = useState('');
  const [number, setNumber] = useState('');
  const [size, setSize] = useState('');
  const [role, setRole] = useState('');
  const [res, setRes] = useState('');
  const [resToggle, setResToggle] = useState(false);
  const [loading, setLoading] = useState(false);
  const [tabValue, setTabValue] = useState(0);
  const [currentCredit, setCurrentCredit] = useState(null);
  const [innerPlanType, setInnerPlanType] = useState(0);
  const [prices, setPrices] = useState([["30", "60", "90"], ["120", "180", "240"], ["360", "480", "720"], "Custom"]);

  const handleSetTabValue = (event, newValue) => {
    setTabValue(newValue);

    if (event.currentTarget.id === "Annual") {
      setPrices([["24", "48", "72"], ["96", "144", "192"], ["288", "384", "576"], "Custom"]);
    } else {
      setPrices([["30", "60", "90"], ["120", "180", "240"], ["360", "480", "720"], "Custom"]);
    }
  };


  const handleInnerType = (type) => {
    setInnerPlanType(type);
  } 

  return (
    <>
        {showForm ? 
          <About 
            name={name}
            setName={setName}
            email={email}
            setEmail={setEmail}
            businessName={businessName}
            setBusinessSetName={setBusinessSetName}
            number={number}
            setNumber={setNumber}
            res={res}
            loader={loading}
            resToggle={resToggle} 
            size={size}
            setSize={setSize}
            role={role}
            setRole={setRole}
          />
            :
          <Card>
            <Header tabValue={tabValue} tabHandler={handleSetTabValue} />
            <Container>
              <PricingCards 
                prices={prices}
                currentPlan={currentPlan}
                currentPlanYearly={currentPlanYearly}
                personalPlan={personalPlan}
                personalPlanYearly={personalPlanYearly}
                businessPlan={businessPlan}
                businessPlanYearly={businessPlanYearly}
                enterprisePlan={enterprisePlan}
                tabValue={tabValue}
                handleInnerType={handleInnerType}
                currentCredit={currentCredit}
              />
            </Container>
          </Card>
        }
      </>
  );
}

export default Premium;
