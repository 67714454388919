import VuiBox from 'components/VuiBox';
import React, { useState } from 'react';
import VolumeOffIcon from '@mui/icons-material/VolumeOff';
import VolumeUpIcon from '@mui/icons-material/VolumeUp';
import { Tooltip } from '@mui/material';

const VideoComponent = () => {
  const [muted, setMuted] = useState(true);

  const toggleMute = () => {
    setMuted(prevMuted => !prevMuted);
  };

  return (
    <VuiBox>
      <div style={{ position: 'relative', width: '100%', height: '100%' }}>
        <video
          autoPlay
          muted={muted}
          loop
          playsInline
          style={{
            borderRadius: '15px',
            marginTop: '25px',
            display: 'flex',
            alignItems: 'center',
            opacity: 1,
            width: '60vw',
            boxShadow: '0 26px 58px 0 rgba(10, 0, 0, .82), 0 5px 14px 0 rgba(10, 0, 0, .18)'
          }}
        >
          <source src='https://quickvideo.azureedge.net/quickvideo/website/final%20ai%20video_.mp4?updatedAt=1718271132913' type="video/mp4" />
        </video>
        <button
          onClick={toggleMute}
          style={{
            position: 'absolute',
            bottom: '15px',
            right: '20px',
            background: 'transparent',
            border: 'none',
            cursor: 'pointer',
            color: 'white',
            fontSize: '16px'
          }}
        >
          {muted ? 
            <Tooltip title='Unmute' placement='right'>
              <VolumeOffIcon color='black' fontSize={window.innerWidth < 800 ? 'small' : 'large'} />
            </Tooltip> 
            : 
            <Tooltip title='Mute' placement='right'>
              <VolumeUpIcon color='black' fontSize={window.innerWidth < 800 ? 'small' : 'large'} />
            </Tooltip>}
        </button>
      </div>
    </VuiBox>
  )
}

export default VideoComponent