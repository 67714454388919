import React from 'react';
import './Pagination.css';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';

const Pagination = ({ currentPage, totalPages, paginate }) => {
  const pagesToShow = 3;

  const pageNumbers = [];

  // for (let i = 1; i <= totalPages; i++) {
  //   pageNumbers.push(i);
  // }

  for (let i = Math.max(1, currentPage - pagesToShow); i <= Math.min(totalPages, currentPage + pagesToShow); i++) {
    pageNumbers.push(i);
  }

  return (
    <div className="pagination">
      <button className='button_arrow' onClick={() => paginate(currentPage - 1)} disabled={currentPage === 1}>
        <ChevronLeftIcon />
      </button>
      {pageNumbers.map((pageNumber) => (
        <button
          key={pageNumber}
          onClick={() => paginate(pageNumber)}
          className={pageNumber === currentPage ? 'button_number active' : 'button_number'}
        >
          {pageNumber}
        </button>
      ))}
      <button className='button_arrow' onClick={() => paginate(currentPage + 1)} disabled={currentPage === totalPages}>
        <ChevronRightIcon />
      </button>
    </div>
  );
};

export default Pagination;
