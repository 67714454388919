import { useState } from "react";

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";

// Vision UI Dashboard PRO React components
import VuiBox from "components/VuiBox";
import VuiButton from "components/VuiButton";
// import Upload from "./Upload";
// import Script from "./Script";
// import Variables from "./Variables";
// import Dataset from "./Dataset";
// import Voice from "./Voice";
// import Preview from "./Preview";
// import Details from "./Details";
import axios from "axios";
import MessagePreview from "./steps/MessagePreview";
import BgImage from "./steps/BgImage";
import CompanyLogo from "./steps/CompanyLogo";
import Preview from "./steps/Preview";
import Sound from "./steps/Sound";
// import { CircularProgress } from "@mui/material";

function StepsIndex({setStepLoading, details, setDetails, captureSnapshot, snapshot, componentRef, setRes, setResLoading}) {
  const [activeStep, setActiveStep] = useState(0);
  const steps = getSteps();
  const isLastStep = activeStep === steps.length - 1;
  const handleBack = () => setActiveStep(activeStep - 1);
  const handleNext = () => setActiveStep(activeStep + 1);

  const handleSubmit = async () => {
    await captureSnapshot();
    const data = {
      details,
      snapshot: details.backgroundImage !== null && snapshot,
    };
    
    const headers = {
      accept: 'application/json',
      'content-type': 'multipart/form-data',
    };
    
    const options = {
      method: 'POST',
      url: 'https://api.quickvideo.ai/v1/web_videos',
      headers: headers,
      data: data
    };
    setActiveStep(activeStep + 1);
    setStepLoading(true); 
    setResLoading(true);
    
    axios(options)
      .then(response => {
        setRes(response.data);
        setStepLoading(false);
        setResLoading(false);
      })
      .catch(error => {
        console.error(error);
        setStepLoading(false);
        setResLoading(false);
      });
  }

  function getSteps() {
    return ["Message", "Sound", "Background", "Logo (optional)", "Preview"];
  }
  
  function getStepContent(stepIndex) {
    switch (stepIndex) {
      case 0:
        return <MessagePreview details={details} setDetails={setDetails} />;
      case 1: 
        return <Sound details={details} setDetails={setDetails} />
      case 2:
        return <BgImage details={details} setDetails={setDetails} />;
      case 3:
        return <CompanyLogo details={details} setDetails={setDetails} />;
      case 4: 
        return <Preview details={details} setDetails={setDetails} captureSnapshot={captureSnapshot} componentRef={componentRef} />
      default:
        return null;
    }
  }

  return (
      <VuiBox sx={{marginTop: window.innerWidth < 768 ? '' : '-50px'}}>
        <Grid container justifyContent="center" alignItems='center' sx={{ height: "100%" }}>
          <Grid item xs={12} lg={12}>
            <Stepper activeStep={activeStep} alternativeLabel sx={{padding: 0}}>
              {steps.map((label) => (
                <Step key={label}>
                  <StepLabel>{label}</StepLabel>
                </Step>
              ))}
            </Stepper>
            <Card>
              <VuiBox sx={{height: '100vh'}}>
                {getStepContent(activeStep)}
                <VuiBox mt={2} width="100%" display="flex" justifyContent="space-between">
                  {activeStep === 0 || activeStep === 6 ? (
                    <VuiBox />
                  ) : (
                    <VuiButton
                      variant="gradient"
                      sx={{ minWidth: "100px" }}
                      color="light"
                      onClick={handleBack}
                    >
                      prev
                    </VuiButton>
                  )}
                  <VuiButton
                    sx={{ minWidth: "100px" }}
                    color="info"
                    onClick={isLastStep ? handleSubmit : handleNext}
                    // disabled={activeStep === 0 ? (details.from === '' || details.message === '') : activeStep === 1 ? (details.selectedLanguage === null || details.selectedVoice === null || details.style === null) : activeStep === 2 ? details.backgroundImage === null && details.bgColor === '' : isLastStep ? details.presenter_id === '' : false}
                  >
                    {isLastStep ? "submit" : "next"}
                  </VuiButton>
                </VuiBox>
              </VuiBox>
            </Card>
          </Grid>
        </Grid>
      </VuiBox>
  );
}

export default StepsIndex;
