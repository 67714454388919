let videoRef;
let peerConnection;
let streamId;
let sessionId;
let sessionClientAnswer;
let statsIntervalId;
let videoIsPlaying;
let lastBytesReceived;
let idleBotVideo;
let gender;

const RTCPeerConnection = (
  window.RTCPeerConnection
  ).bind(window);
  
const DID_API = {url: 'https://api.d-id.com', key: 'a3Jpc2hAa3dpcXJlcGx5Lmlv:qPAcbgap17hkT8L5BvbMf'}

export const initializeVideo = (videoRefs, video, voice) => {
  videoRef = videoRefs;
  idleBotVideo = video;
  gender= voice
  return (idleBotVideo, gender);
}

// const peerStatusLabel = document.getElementById('peer-status-label');
// const iceStatusLabel = document.getElementById('ice-status-label');
// const iceGatheringStatusLabel = document.getElementById('ice-gathering-status-label');
// const signalingStatusLabel = document.getElementById('signaling-status-label');
// const streamingStatusLabel = document.getElementById('streaming-status-label');

// const connectButton = document.getElementById('connect-button');
export const connect = async (url, user_id='507a9c79-32fb-4920-aabd-3f2395b11723') => {
  if (peerConnection && peerConnection.connectionState === 'connected') {
    return;
  }

  stopAllStreams();
  closePC();

  const sessionResponse = await fetchWithRetries('https://quick.quickvideo.ai/v1/talks/streams', {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${user_id}`,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      source_url: url,
    }),
  });

  const { id: newStreamId, offer, ice_servers: iceServers, session_id: newSessionId } = await sessionResponse.json();
  streamId = newStreamId;
  sessionId = newSessionId;

  try {
    sessionClientAnswer = await createPeerConnection(offer, iceServers, user_id);
  } catch (e) {
    console.log('error during streaming setup', e);
    stopAllStreams();
    closePC();
    return;
  }

  const sdpResponse = await fetch(`https://quick.quickvideo.ai/v1/talks/streams/${streamId}/sdp`, {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${user_id}`,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      answer: sessionClientAnswer,
      session_id: sessionId,
    }),
  });
};

// const talkButton = document.getElementById('talk-button');
export const talk = async (text, user_id='507a9c79-32fb-4920-aabd-3f2395b11723') => {
  // connectionState not supported in firefox
  if (peerConnection?.signalingState === 'stable' || peerConnection?.iceConnectionState === 'connected') {
    const talkResponse = await fetchWithRetries(`https://quick.quickvideo.ai/v1/talks/streams/${streamId}`, {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${user_id}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        script: {
          type: 'text',
          // audio_url: 'https://d-id-public-bucket.s3.us-west-2.amazonaws.com/webrtc.mp3',
          "provider": {
            "type": "microsoft",
            "voice_id": gender
          },
          "ssml": "false",
          "input": text
        },
        driver_url: 'bank://lively/',
        config: {
          stitch: true,
        },
        session_id: sessionId,
      }),
    });
  }
};

// const destroyButton = document.getElementById('destroy-button');
export const destroy = async (user_id='507a9c79-32fb-4920-aabd-3f2395b11723') => {
  await fetch(`https://quick.quickvideo.ai/v1/talks/streams/${streamId}`, {
    method: 'DELETE',
    headers: {
      Authorization: `Bearer ${user_id}`,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ session_id: sessionId }),
  });

  stopAllStreams();
  closePC();
};

// function onIceGatheringStateChange() {
//   iceGatheringStatusLabel.innerText = peerConnection.iceGatheringState;
//   iceGatheringStatusLabel.className = 'iceGatheringState-' + peerConnection.iceGatheringState;
// }
function onIceCandidate(event, user_id) {
  console.log('onIceCandidate', event);
  if (event.candidate) {
    const { candidate, sdpMid, sdpMLineIndex } = event.candidate;

    fetch(`https://quick.quickvideo.ai/v1/talks/streams/${streamId}/ice`, {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${user_id}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        candidate,
        sdpMid,
        sdpMLineIndex,
        session_id: sessionId,
      }),
    });
  }
}
// function onIceConnectionStateChange() {
//   iceStatusLabel.innerText = peerConnection.iceConnectionState;
//   iceStatusLabel.className = 'iceConnectionState-' + peerConnection.iceConnectionState;
//   if (peerConnection.iceConnectionState === 'failed' || peerConnection.iceConnectionState === 'closed') {
//     stopAllStreams();
//     closePC();
//   }
// }
// function onConnectionStateChange() {
//   // not supported in firefox
//   peerStatusLabel.innerText = peerConnection.connectionState;
//   peerStatusLabel.className = 'peerConnectionState-' + peerConnection.connectionState;
// }
// function onSignalingStateChange() {
//   signalingStatusLabel.innerText = peerConnection.signalingState;
//   signalingStatusLabel.className = 'signalingState-' + peerConnection.signalingState;
// }

function onVideoStatusChange(videoIsPlaying, stream) {
  // let status;
  if (videoIsPlaying) {
    // status = 'streaming';
    const remoteStream = stream;
    setVideoElement(remoteStream);
  } else {
    // status = 'empty';
    playIdleVideo();
  }
  // streamingStatusLabel.innerText = status;
  // streamingStatusLabel.className = 'streamingState-' + status;
}

function onTrack(event) {
  if (!event.track) return;

  statsIntervalId = setInterval(async () => {
    const stats = await peerConnection.getStats(event.track);
    stats.forEach((report) => {
      if (report.type === 'inbound-rtp' && report.mediaType === 'video') {
        const videoStatusChanged = videoIsPlaying !== report.bytesReceived > lastBytesReceived;

        if (videoStatusChanged) {
          videoIsPlaying = report.bytesReceived > lastBytesReceived;
          onVideoStatusChange(videoIsPlaying, event.streams[0]);
        }
        lastBytesReceived = report.bytesReceived;
      }
    });
  }, 500);
}

async function createPeerConnection(offer, iceServers, user_id) {
  if (!peerConnection) {
    peerConnection = new RTCPeerConnection({ iceServers });
    // peerConnection.addEventListener('icegatheringstatechange', onIceGatheringStateChange, true);
    peerConnection.addEventListener('icecandidate', (event) => {
      onIceCandidate(event, user_id);
    });
    // peerConnection.addEventListener('iceconnectionstatechange', onIceConnectionStateChange, true);
    // peerConnection.addEventListener('connectionstatechange', onConnectionStateChange, true);
    // peerConnection.addEventListener('signalingstatechange', onSignalingStateChange, true);
    peerConnection.addEventListener('track', onTrack, true);
  }

  await peerConnection.setRemoteDescription(offer);
  // console.log('set remote sdp OK');

  const sessionClientAnswer = await peerConnection.createAnswer();
  // console.log('create local sdp OK');

  await peerConnection.setLocalDescription(sessionClientAnswer);
  // console.log('set local sdp OK');

  return sessionClientAnswer;
}

function setVideoElement(stream) {
  if (!stream) return;
  // talkVideo.srcObject = stream;
  // talkVideo.loop = false;

  // // safari hotfix
  // if (talkVideo.paused) {
  //   talkVideo
  //     .play()
  //     .then((_) => {})
  //     .catch((e) => {});
  // }
  if (videoRef && videoRef.current) {
    videoRef.current.srcObject = stream;
    videoRef.current.loop = false;

    // Safari hotfix
    if (videoRef.current.paused) {
      videoRef.current
        .play()
        .then((_) => {})
        .catch((e) => {});
    }
  } else {
    console.error('talkVideoRef is null or current is undefined');
  }
}

function playIdleVideo() {
  // talkVideo.srcObject = undefined;
  // talkVideo.src = './or_idle.mp4';
  // talkVideo.loop = true;
  if (videoRef && videoRef.current) {
    videoRef.current.srcObject = null;
    videoRef.current.src = idleBotVideo;
    videoRef.current.loop = true;
  } else {
    console.error('talkVideoRef is null or current is undefined');
  }
}

function stopAllStreams() {
  // if (talkVideo.srcObject) {
  //   console.log('stopping video streams');
  //   talkVideo.srcObject.getTracks().forEach((track) => track.stop());
  //   talkVideo.srcObject = null;
  // }
  if (videoRef  && videoRef.current && videoRef.current.srcObject) {
    videoRef.current.srcObject.getTracks().forEach((track) => track.stop());
    videoRef.current.srcObject = null;
  }
}

function closePC(pc = peerConnection) {
  if (!pc) return;
  console.log('stopping peer connection');
  pc.close();
  // pc.removeEventListener('icegatheringstatechange', onIceGatheringStateChange, true);
  pc.removeEventListener('icecandidate', onIceCandidate, true);
  // pc.removeEventListener('iceconnectionstatechange', onIceConnectionStateChange, true);
  // pc.removeEventListener('connectionstatechange', onConnectionStateChange, true);
  // pc.removeEventListener('signalingstatechange', onSignalingStateChange, true);
  pc.removeEventListener('track', onTrack, true);
  clearInterval(statsIntervalId);
  // iceGatheringStatusLabel.innerText = '';
  // signalingStatusLabel.innerText = '';
  // iceStatusLabel.innerText = '';
  // peerStatusLabel.innerText = '';
  console.log('stopped peer connection');
  if (pc === peerConnection) {
    peerConnection = null;
  }
}

const maxRetryCount = 3;
const maxDelaySec = 4;

async function fetchWithRetries(url, options, retries = 1) {
  try {
    return await fetch(url, options);
  } catch (err) {
    if (retries <= maxRetryCount) {
      const delay = Math.min(Math.pow(2, retries) / 4 + Math.random(), maxDelaySec) * 1000;

      await new Promise((resolve) => setTimeout(resolve, delay));

      console.log(`Request failed, retrying ${retries}/${maxRetryCount}. Error ${err}`);
      return fetchWithRetries(url, options, retries + 1);
    } else {
      throw new Error(`Max retries exceeded. error: ${err}`);
    }
  }
}