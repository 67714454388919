import React from 'react';
import './Footer.css';
import Logo from './Logo 1.3b9ed676.svg';
import { Button, Typography } from '@mui/material';
import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import TwitterIcon from '@mui/icons-material/Twitter';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import CallIcon from '@mui/icons-material/Call';
import EmailIcon from '@mui/icons-material/Email';
import { Fade } from "react-awesome-reveal"; 
import { NavLink } from "react-router-dom";

const Footer = ({ scrollToSection }) => {
  const runTwoTimes = async (path) => {
    await scrollToSection(path);
    await scrollToSection(path);
  }

  return (
    <div className='footer'>
      <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'flex-start', borderBottom: '1px solid white', paddingBottom: '10px', flexWrap: 'wrap', flexDirection: window.innerWidth <= 500 ? 'column' : 'row'}}>
        <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'space-between', marginBottom: window.innerWidth <= 800 ? 20 : 10}}>
          <NavLink to="/" style={{color: 'whitesmoke'}}>
            <img src={Logo} alt="logo" onClick={() => {runTwoTimes('home')}} style={{marginTop: '-20px', marginLeft: '-10px', cursor: 'pointer'}} height="80px" width="200px" loading="lazy" />
          </NavLink>
          <Typography variant='h6' sx={{marginTop: '0px', fontSize: 14, color: 'whitesmoke'}}>AI Powered Conversational Video <br/> Chatbot Platform.</Typography>
          <div style={{display: "flex", alignItems: 'flex-start', justifyContent: 'space-around', marginTop: '20px'}}>
            <a href="#"> 
            <img border="0" src="https://brand-assets.softwareadvice.com/badge/deeaf833-2162-4cbe-adbe-f8ff585d1241.png" width="90px"/> 
          </a>
            <a href="https://www.capterra.com/p/10022787/QuickVideo/reviews/"> 
          <img border="0" src="https://brand-assets.capterra.com/badge/311fb513-115f-447c-9992-e43525c1c56a.svg" width="90px"/> 
          </a>
          </div>
          {/* <Typography variant='h6' sx={{marginTop: '0px', fontSize: 14, color: 'whitesmoke'}}>Copyright © 2024. QuickVideo.ai <br /> All Rights Reserved</Typography> */}
          <div style={{display: "flex", alignItems: 'flex-start', justifyContent: 'space-between', marginTop: '20px', width: '100px'}}>
            <a href="https://www.linkedin.com/company/quickvideo/" target='_blank' rel='noreferrer'>
              <LinkedInIcon color='white' sx={{'&:hover': {color: 'skyblue'}}} />
            </a>
            <a href="https://www.instagram.com/quickvideoai/" target='_blank' rel='noreferrer'>
              <InstagramIcon color='white' sx={{'&:hover': {color: 'skyblue'}}} />
            </a>
            <a href="https://twitter.com/quickvideoai" target='_blank' rel='noreferrer'>
              <TwitterIcon color='white' sx={{'&:hover': {color: 'skyblue'}}} />
            </a>  
          </div>
        </div>
        <div style={{display: 'flex', flexDirection: 'column', marginBottom: window.innerWidth <= 800 ? 20 : 10}}>
          <Typography variant='h6' style={{color: 'whitesmoke', fontWeight: 600, marginBottom: "5px", fontSize: 20}}>Menu</Typography>
          <div>
            <NavLink to="/" style={{color: 'whitesmoke'}}>
              <p style={{fontSize: 16}} onClick={() => {scrollToSection('home');}}>Home</p>
            </NavLink>
            <NavLink to="/" style={{color: 'whitesmoke'}}>
              <p style={{fontSize: 16}} onClick={() => {scrollToSection('information');}}>How It Works</p>
            </NavLink>
            {/* <NavLink to="/faq" style={{color: 'whitesmoke'}}>
              <p style={{fontSize: 16}} onClick={() => {scrollToSection('questions'); setShowPricing(false); setShowContact(false);}}>FAQs</p>
            </NavLink> */}
            {/* <NavLink to="/blogs" style={{color: 'whitesmoke'}}>
              <p style={{fontSize: 16}} onClick={handleBlogsClick}>Blogs</p>
            </NavLink> */}
            <NavLink to="/blogs" style={{color: 'whitesmoke'}}>
              <p style={{fontSize: 16}}>Blogs</p>
            </NavLink>
            <NavLink to="/quickvideo-pricing" style={{color: 'whitesmoke'}}>
              <p style={{fontSize: 16}}>Pricing</p>
            </NavLink>
            <NavLink to="/contact-us" style={{color: 'whitesmoke'}}>
              <p style={{fontSize: 16}}>Contact Us</p>
            </NavLink>
            {/* <a href='https://quickvideo.ai/blog1.html' style={{color: 'whitesmoke'}}>
              <p style={{fontSize: 16}}>New Blog</p>
            </a> */}
          </div>
        </div>
        <div style={{display: 'flex', flexDirection: 'column', marginBottom: window.innerWidth <= 800 ? 20 : 10}}>
          <Typography variant='h6' style={{color: 'whitesmoke', fontWeight: 600, marginBottom: "5px", fontSize: 20}}>Products</Typography>
          <div>
            <NavLink to="/text-to-video-ai-generator" style={{color: 'whitesmoke'}}>
              <p style={{fontSize: 16}}>Text-to-Video</p>
            </NavLink>
            <NavLink to="/personalized-ai-video" style={{color: 'whitesmoke'}}>
              <p style={{fontSize: 16}}>Video Personalization</p>
            </NavLink>
            <NavLink to="/conversational-ai" style={{color: 'whitesmoke'}}>
              <p style={{fontSize: 16}}>Conversational Video Chatbot</p>
            </NavLink>
            <NavLink to="/saas-tutorial" style={{color: 'whitesmoke'}}>
              <p style={{fontSize: 16}}>Generate Tutorial Video</p>
            </NavLink>
          </div>
        </div>
        <div style={{display: 'flex', flexDirection: 'column', marginBottom: window.innerWidth <= 800 ? 20 : 10}}>
          <Typography variant='h6' style={{color: 'whitesmoke', fontWeight: 600, marginBottom: "5px", fontSize: 20}}>Features</Typography>
          <div>
            <a href="https://www.quickvideo.ai/features/features-audio.html"  style={{color: 'whitesmoke'}}>
              <p style={{fontSize: 16}}>Audio</p>
            </a>
            <a href="https://www.quickvideo.ai/features/features-ai-presenters.html"  style={{color: 'whitesmoke'}}>
              <p style={{fontSize: 16}}>AI Avatars</p>
            </a>
            <a href="https://www.quickvideo.ai/features/features-video-editor.html"  style={{color: 'whitesmoke'}}>
              <p style={{fontSize: 16}}>Video Editor</p>
            </a>
            <a href="https://www.quickvideo.ai/features/features-voice-cloning.html"  style={{color: 'whitesmoke'}}>
              <p style={{fontSize: 16}}>Video Cloning</p>
            </a>
            <a href="https://www.quickvideo.ai/features/features-video-library.html"  style={{color: 'whitesmoke'}}>
              <p style={{fontSize: 16}}>Video Library</p>
            </a>
            <a href="https://www.quickvideo.ai/features/features-languages.html" style={{color: 'whitesmoke'}}>
              <p style={{fontSize: 16}}>Multilingual AI Voices</p>
            </a>
            {/* <NavLink to="/saas-tutorial" style={{color: 'whitesmoke'}}>
              <p style={{fontSize: 16}}>Generate Tutorial Video</p>
            </NavLink> */}
          </div>
        </div>
        {/* <div style={{display: 'flex', flexDirection: 'column', marginBottom: window.innerWidth <= 800 ? 20 : 10}}>
          <Typography variant='h6' style={{color: 'whitesmoke', fontWeight: 600, marginBottom: "5px", fontSize: 20}}>Solutions</Typography>
          <div>
            <NavLink to="/sales&marketing" style={{color: 'whitesmoke'}}>
              <p style={{fontSize: 16}}>Sales & Marketing</p>
            </NavLink>
            <NavLink to="/customer_service" style={{color: 'whitesmoke'}}>
              <p style={{fontSize: 16}}>Customer Service</p>
            </NavLink>
            <NavLink to="/enterprises_employee_engagement" style={{color: 'whitesmoke'}}>
              <p style={{fontSize: 16}}>Enterprises/Employee Engagement</p>
            </NavLink>
            <NavLink to="/education" style={{color: 'whitesmoke'}}>
              <p style={{fontSize: 16}}>Education</p>
            </NavLink>
            <NavLink to="/learning&development" style={{color: 'whitesmoke'}}>
              <p style={{fontSize: 16}}>Learning & Development</p>
            </NavLink>
            <NavLink to="/socialmedia" style={{color: 'whitesmoke'}}>
              <p style={{fontSize: 16}}>Social Media</p>
            </NavLink>
          </div>
        </div> */}
        <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'space-between', marginBottom: window.innerWidth <= 800 ? 20 : 10}}>
          <Typography variant='h6' sx={{color: 'whitesmoke', fontWeight: 600, fontSize: 20, marginBottom: "5px"}}>Contact Us</Typography>
          <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'space-between', alignItems: 'flex-start'}}>
            <div style={{display: 'flex', justifyContent: 'flex-start', alignItems: 'center'}}>
              <LocationOnIcon />
              <p style={{fontSize: 16, color: 'whitesmoke', marginLeft: '10px'}}>SFO|DXB|BLR</p>
            </div>
            {/* <div style={{display: 'flex', justifyContent: 'flex-start', alignItems: 'center'}}>
              <CallIcon />
              <p style={{fontSize: 16, color: 'whitesmoke', marginLeft: '10px'}}><a href="tel:+91-8501976285" style={{color: 'white'}}>+91-8501976285</a></p>
            </div> */}
            <div style={{display: 'flex', justifyContent: 'flex-start', alignItems: 'center'}}>
              <EmailIcon />
              <p style={{fontSize: 16, color: 'whitesmoke', marginLeft: '10px'}}><a href="mailto:hello@quickvideo.ai" style={{color: 'white'}}>hello@quickvideo.ai</a></p>
            </div>
          </div>
        </div>
        {/* <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'space-between', alignItems: 'flex-start'}}>
          <Typography variant='h6' sx={{color: 'whitesmoke', fontWeight: 600, marginBottom: "5px"}}>Get Started</Typography>
          <input type='text' style={{background: 'transparent', border: "1px solid gray", padding: "5px", outline: 'none', color: 'whitesmoke'}} placeholder='E-mail' />
          <Button variant='contained' sx={{height: '30px', marginTop: '10px', color: 'whitesmoke'}}>Sign Up</Button>
        </div> */}
      </div>
      <div style={{display: 'flex', alignItems: 'center', justifyContent: window.innerWidth <= 800 ? 'center' : 'space-between', marginTop: '10px', marginBottom: '10px', flexWrap: 'wrap', textAlign: window.innerWidth <= 800 ? 'center' : '', paddingBottom: '20px'}}>
        <div style={{display: 'flex', alignItems: 'center', justifyContent: 'space-between', minWidth: '300px', flexDirection: window.innerWidth <= 800 ? 'column' : 'row'}}>
          <Typography variant='h6' sx={{color: 'whitesmoke', fontWeight: 600}}>
            <NavLink to="/terms-of-service" style={{color: 'whitesmoke'}}>
               Terms and Conditions
            </NavLink></Typography>
          <Typography variant='h6' sx={{color: 'whitesmoke', fontWeight: 600}}>
            <NavLink to="/privacy-policy" style={{color: 'whitesmoke'}}>
               Privacy Policy
            </NavLink>
           </Typography>
        </div>
        <div>
          <Typography variant='h6' sx={{color: 'whitesmoke', fontWeight: 600}}>Copyright © 2024. QuickVideo.ai <span style={{marginLeft: '10px'}}>All Rights Reserved.</span></Typography>
        </div>
      </div>
    </div>
  )
}

export default Footer;