import React from 'react'
import {cardDetails} from "./carousel-config";
import CarouselItem from "./CarouselItem";
import './carousel.css';

const CarouselSection = () => {
  return (
    <div className="carousel-container">
      <h3 className="carousel-title">LOVED BY THOUSANDS OF USERS FROM COMPANIES LIKE</h3>
      {/* <div className="carousel-overlay left-overlay" /> */}
      <div className="carousel-track">
        {cardDetails.map((detailKey) => {
          return (
            <CarouselItem
              id={detailKey.id}
              imgUrl={detailKey.imgUrl}
              imgTitle={detailKey.title}
            />
          );
        })}
        {cardDetails.map((detailKey) => {
          return (
            <CarouselItem
              id={detailKey.id}
              imgUrl={detailKey.imgUrl}
              imgTitle={detailKey.title}
            />
          );
        })}
      </div>
      {/* <div className="carousel-overlay right-overlay" /> */}
    </div>
  );
}

export default CarouselSection