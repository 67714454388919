/** 

=========================================================
* Vision UI PRO React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/vision-ui-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com/)

* Design and Coded by Simmmple & Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Visionware.

*/

// prop-type is a library for typechecking of props
import PropTypes from "prop-types";

// Vision UI Dashboard PRO React components
import VuiBox from "components/VuiBox";
import VuiTypography from "components/VuiTypography";
import VuiInput from "components/VuiInput";

import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';

function FormField({ label, type, phoneInputStyle, value, onChange, ...rest }) {
  return (
    <>
      <VuiBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
        <VuiTypography
          component="label"
          variant="caption"
          textTransform="capitalize"
          color="white"
          fontSize="16px"
        >
          {label}
        </VuiTypography>
      </VuiBox>
      {type === "phone" ? (
        <PhoneInput
          country={'in'}
          value={value}
          onChange={onChange}
          inputStyle={phoneInputStyle}
          {...rest}
        />
      ) : (
        <VuiInput
          sx={({ borders: { borderWidth }, palette: { borderCol } }) => ({
            border: `${borderWidth[1]} solid ${borderCol.main}`,
          })}
          type={type}
          value={value}
          onChange={onChange}
          {...rest}
        />
      )}
    </>
  );
}

// typechecking props for FormField
FormField.propTypes = {
  label: PropTypes.string.isRequired,
  type: PropTypes.string,
  value: PropTypes.string,
  onChange: PropTypes.func,
  phoneInputStyle: PropTypes.object,
};

export default FormField;
