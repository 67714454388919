import { Button, Divider } from '@mui/material'
import VuiTypography from 'components/VuiTypography';
import React from 'react';
import './Solutions.css';
import first from './images/Take a screen recording.webp';
import second from './images/Human Avatar with voice.webp';
import third from './images/Extract the video.webp';
import fourth from './images/Digital avatars that.webp';
import fifth from './images/Create a fully professional script.webp';
import arrow from './images/texttovideo/right-arrow_2252525.png';
import downArrow from './images/texttovideo/down-arrow_266.png';
import { Slide, Flip, Zoom, Fade } from "react-awesome-reveal";
import ReactPlayer from 'react-player';
import Form from '../Form/Form';
import AutoAwesomeIcon from '@mui/icons-material/AutoAwesome';

function VideoTutorial() {
  const redirectToSignUp = () => {
    window.open('https://app.quickvideo.ai/signup', '_blank');
  };

  const saasTool = [
    {
      video: ' https://youtu.be/puDsUWCXMLI',
      heading: 'Customer Onboarding',
      desc: 'Say NO to too much hustle onboarding customers. Videos for helping users onboard with ease. Be it basic details to verification processes, a demo video answers it all.'
    },
    {
      video: 'https://youtu.be/cYHg0g0h-Rc',
      heading: 'Employee Training',
      desc: 'Reduce Employee Training and Onboarding time by ~60% using real platform videos. Get your team up-to-speed on internal processes and systems'
    },
    {
      video: 'https://youtu.be/pH3qiQ1pjdI',
      heading: 'Self Service Portals',
      desc: 'Enhance self-service options by providing video tutorials on common issues and how to resolve them.'
    },
    {
      video: 'https://youtu.be/9FoD3vguQ90',
      heading: "FAQ's and How-Tos",
      desc: 'Create detailed video answers to frequently asked questions. Make quick how-to videos accessible to anyone to enhance usage experience.'
    },
    {
      video: 'https://youtu.be/SGg-u02DbJk',
      heading: 'Product Marketing',
      desc: 'Be it quick demos in newsletters, product updates or a major launch, building high quality videos to help users feel in the driving seat.'
    },
  ];

  return (
    <div className='videoTutorial'>
      <div className="videoTutorialBanner">
        <h1 style={{color: '#3692DD', textShadow: '1px 0px 4px rgba(50,83,114,0.6)', fontSize: window.innerWidth <= 1000 ? 26 : 36, lineHeight: 1.2, textAlign: 'center', }}>SaaS tutorial<br /> Build How to platform guides with AI</h1>
        <h2 style={{fontWeight: 400, fontSize: window.innerWidth <= 1000 ? 16 : 18, textAlign: 'center', color: 'white', textShadow: '1px 0px 4px rgba(50,83,114,0.6)', marginTop: '10px'}}>Build global standard high-quality tutorial videos using QuickVideo’s SaaS Tutorial Generator. <br /> With AI Avatars and Voiceovers, make onboarding as easy as it gets.</h2>
        <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column', marginTop: '20px'}}>
          <Button sx={{background: 'white', color: '#000', borderRadius: '30px', fontWeight: 600, fontSize: 12, maxWidth: '200px',fontFamily: '"Plus Jakarta Display", Helvetica, Arial, sans-serif !important', marginTop: 5, '&:hover': {color: "#fff"}, marginLeft: 2}} variant='contained' onClick={redirectToSignUp}>
            Try now <AutoAwesomeIcon sx={{marginLeft: '5px'}} />
          </Button>
          {/* <video autoPlay playsInline controls style={{ width: "70vw", height: "70vh", marginTop: '50px' }}>
            <source src="https://www.youtube.com/watch?v=MFnu_faRZvU" type="video/mp4" />
          </video> */}
          <div style={{marginTop: '50px'}}>
            <ReactPlayer
              url="https://www.youtube.com/watch?v=MFnu_faRZvU"
              width="70vw"
              height={window.innerWidth < 768 ? "30vh" : "70vh"}
              autoPlay={true}
            />
          </div>
        </div>
      </div>
      <Divider light />
      <div className='videoTutorialContent'>
        <h2 style={{fontWeight: 400, fontSize: window.innerWidth <= 1000 ? 20 : 40, textAlign: 'center', color: 'white', textShadow: '1px 0px 4px rgba(50,83,114,0.6)'}}>Here's how SaaS builders are using our tool</h2>
        <div style={{display: 'flex', overflow: 'hidden', flexWrap: 'wrap', justifyContent: 'space-evenly', marginTop: '20px', width: '100%'}}>
          {saasTool.map((item, index) => (
            <div key={index} style={{maxWidth: window.innerWidth <= 768 ? '100%' : '350px', height: '300px', display: 'flex', flexDirection: 'column', alignItems: 'center', margin: '25px'}}>
              <ReactPlayer url={item.video} width='100%' style={{borderRadius: '20px'}} />
              <Slide direction="up">
                <h3 style={{fontWeight: 400, fontSize: window.innerWidth <= 1000 ? 16 : 22, textAlign: 'center', color: 'white', textShadow: '1px 0px 4px rgba(50,83,114,0.6)'}}>{item.heading}</h3>
                <p style={{fontWeight: 400, fontSize: window.innerWidth <= 1000 ? 10 : 12, textAlign: 'center', color: 'lightgray', textShadow: '1px 0px 4px rgba(50,83,114,0.6)'}}>{item.desc}</p>
              </Slide>
            </div>
          ))}
        </div>
      </div>
      <div className='videoTutorialContent'>
        <h2 style={{fontWeight: 400, fontSize: window.innerWidth <= 1000 ? 20 : 40, textAlign: 'center', color: 'white', textShadow: '1px 0px 4px rgba(50,83,114,0.6)'}}>Create mind-blowing videos in 3 simple steps</h2>
        <div className="contentInnerTable">
          <Slide direction="left">
            <div className='eachInnerContent'>
              <video src="https://quickvideo.blob.core.windows.net/quickvideo/web_saas_tutorial/upload .mp4" width={250} autoPlay loop muted  style={{borderRadius: '20px', marginBottom: '10px'}} />
              <VuiTypography variant='h3' textAlign='center' sx={{color: '#3692DD'}}>
                Upload 
              </VuiTypography>
              <VuiTypography variant='h6' color='white' textAlign='center'>
                Upload a recorded session of your <br /> platform
              </VuiTypography>
            </div>
          </Slide>
          {window.innerWidth >= 1200 && <img src={arrow} alt="arrow" width={100} style={{filter: 'invert(1)'}} loading="lazy" />}
          {window.innerWidth < 1200 && <img src={downArrow} alt="arrow" width={100} style={{filter: 'invert(1)'}} loading="lazy" />}
          <Fade cascade>
            <div className='eachInnerContent'>
              <video src="https://quickvideo.blob.core.windows.net/quickvideo/web_saas_tutorial/edit one .mp4" width={250} autoPlay loop muted  style={{borderRadius: '20px', marginBottom: '10px'}} />
              <VuiTypography variant='h3' textAlign='center' sx={{color: '#3692DD'}}>
                Edit
              </VuiTypography>
              <VuiTypography variant='h6' color='white' textAlign='center'>
                Change transcript, add AI Avatar and voiceover, <br /> add intro and outro
              </VuiTypography>
            </div>
          </Fade>
          {window.innerWidth >= 1200 && <img src={arrow} alt="arrow" width={100} style={{filter: 'invert(1)'}} loading="lazy" />}
          {window.innerWidth < 1200 && <img src={downArrow} alt="arrow" width={100} style={{filter: 'invert(1)'}} loading="lazy" />}
          <Slide direction="right">
            <div className='eachInnerContent'>
              <video src="https://quickvideo.blob.core.windows.net/quickvideo/web_saas_tutorial/Export .mp4" width={250} autoPlay loop muted  style={{borderRadius: '20px', marginBottom: '10px'}} />
              <VuiTypography variant='h3' textAlign='center' sx={{color: '#3692DD'}}>
                Export
              </VuiTypography>
              <VuiTypography variant='h6' color='gray' textAlign='center'>
                Done! Export and your video is <br /> ready.
              </VuiTypography>
            </div>
          </Slide>
        </div>
      </div>
      <div className='videoTutorialContent'>
        <h2 style={{fontWeight: 400, fontSize: window.innerWidth <= 1000 ? 20 : 40, textAlign: 'center', color: 'white', textShadow: '1px 0px 4px rgba(50,83,114,0.6)'}}>How does it work ?</h2>
        <div className="contentInnerTable">
          <Slide direction="left">
            <div className='eachInnerContent'>
              <video src="https://quickvideo.blob.core.windows.net/quickvideo/web_saas_tutorial/upload 2 long video.mp4" width={250} autoPlay loop muted  style={{borderRadius: '20px', marginBottom: '10px'}} />
              <VuiTypography variant='h6' color='white' textAlign='center'>
                The user uploads video on the <br /> platform
              </VuiTypography>
            </div>
          </Slide>
          {window.innerWidth >= 1200 && <img src={arrow} alt="arrow" width={100} style={{filter: 'invert(1)'}} loading="lazy" />}
          {window.innerWidth < 1200 && <img src={downArrow} alt="arrow" width={100} style={{filter: 'invert(1)'}} loading="lazy" />}
          <Fade cascade>
            <div className='eachInnerContent'>
              <video src="https://quickvideo.blob.core.windows.net/quickvideo/web_saas_tutorial/slides .mp4" width={250} autoPlay loop muted  style={{borderRadius: '20px', marginBottom: '10px'}} />
              <VuiTypography variant='h6' color='white' textAlign='center'>
                The video is transcribed and split in multiple <br /> smaller segment of 15 secs each
              </VuiTypography>
            </div>
          </Fade>
          {window.innerWidth >= 1200 && <img src={arrow} alt="arrow" width={100} style={{filter: 'invert(1)'}} loading="lazy" />}
          {window.innerWidth < 1200 && <img src={downArrow} alt="arrow" width={100} style={{filter: 'invert(1)'}} loading="lazy" />}
          <Slide direction="right">
            <div className='eachInnerContent'>
              <video src="https://quickvideo.blob.core.windows.net/quickvideo/web_saas_tutorial/last finally video .mp4" width={250} autoPlay loop muted  style={{borderRadius: '20px', marginBottom: '10px'}} />
              <VuiTypography variant='h6' color='gray' textAlign='center'>
                User can edit, add AI avatar and <br />voiceover and export
              </VuiTypography>
            </div>
          </Slide>
        </div>
      </div>
      <Fade>
        <div style={{display: 'flex', margin: '10%', justifyContent: 'center'}}>
          <div>
            <Slide direction="down" triggerOnce>
              <div style={{margin: '20px', width: window.innerWidth < 768 ? '130px' : '300px', height: window.innerWidth < 768 ? '130px' : '300px', borderRadius: '20px', boxShadow: '0px 0px 100px 0px skyblue', textAlign: 'center', display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column'}}>
                {window.innerWidth < 768 ? <>
                  <h2>100+</h2>
                  <h4>Languages</h4>
                </> : <>
                  <h1>100+</h1>
                  <h3>Languages</h3>
                </>}
              </div>
            </Slide>
            <Slide direction="left" triggerOnce>
              <div style={{margin: '20px', width: window.innerWidth < 768 ? '130px' : '300px', height: window.innerWidth < 768 ? '130px' : '300px', borderRadius: '20px', boxShadow: '0px 0px 100px 0px skyblue', textAlign: 'center', display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column'}}>
                {window.innerWidth < 768 ? <>
                  <h2>120+</h2>
                  <h4>Voices</h4>
                </> : <>
                  <h1>120+</h1>
                  <h3>Voices</h3>
                </>}
              </div>
            </Slide>
          </div>
          <div style={{marginTop: '10%'}}>
            <Slide direction="right" triggerOnce>
              <div style={{margin: '20px', width: window.innerWidth < 768 ? '130px' : '300px', height: window.innerWidth < 768 ? '130px' : '300px', borderRadius: '20px', boxShadow: '0px 0px 100px 0px skyblue', textAlign: 'center', display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column'}}>
                {window.innerWidth < 768 ? <>
                  <h2>140+</h2>
                  <h4>Avatars</h4>
                </> : <>
                  <h1>140+</h1>
                  <h3>Avatars</h3>
                </>}
              </div>
            </Slide>
            <Slide direction="up" triggerOnce>
              <div style={{margin: '20px', width: window.innerWidth < 768 ? '130px' : '300px', height: window.innerWidth < 768 ? '130px' : '300px', borderRadius: '20px', boxShadow: '0px 0px 100px 0px skyblue', textAlign: 'center', display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column'}}>
                {window.innerWidth < 768 ? <>
                  <h2>Ifinite</h2>
                  <h4>Tuorials</h4>
                </> : <>
                  <h1>Infinite</h1>
                  <h3>Tutorials</h3>
                </>}
              </div>
            </Slide>
          </div>
        </div>
      </Fade>
      <h2 style={{fontWeight: 400, fontSize: window.innerWidth <= 1000 ? 20 : 40, textAlign: 'center', color: 'white', textShadow: '1px 0px 4px rgba(50,83,114,0.6)'}}>Start Creating Videos for your SaaS Today</h2>
      <Button sx={{background: 'white', color: '#000', borderRadius: '30px', fontWeight: 600, fontSize: 12, maxWidth: '200px',fontFamily: '"Plus Jakarta Display", Helvetica, Arial, sans-serif !important', margin: '2% auto', '&:hover': {color: "#fff"}}} variant='contained' onClick={redirectToSignUp}>
        Try now <AutoAwesomeIcon sx={{marginLeft: '5px'}} />
      </Button>
      <div style={{marginTop: '50px'}}>
        <Form />
      </div>
    </div>
  )
}

export default VideoTutorial