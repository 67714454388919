import VuiBox from 'components/VuiBox'
import VuiButton from 'components/VuiButton'
import VuiInput from 'components/VuiInput'
import VuiTypography from 'components/VuiTypography'
import React from 'react'

const Newsletter = () => {
  const borderStyle = {
    bottom: 0,
    left: '5px',
    right: '5px',
    height: '0.1px',
    background: 'white',
    width: '200px'
  };

  return (
    <VuiBox sx={{display: 'flex', flexDirection: 'column', justifyContent: 'space-around', alignItems: 'center', padding: '50px', background: 'linear-gradient(180deg, rgba(12,8,24,1) 5%, rgba(47,57,171,1) 80%, rgba(12,8,24,1) 95%)', width: window.innerWidth <= 800 ? '100%' : '1000px', marginLeft: 'auto', marginRight: 'auto', borderRadius: '50px'}}>
      <VuiTypography variant={window.innerWidth <=800 ? 'h4' : 'h3'} color='white'>Join Our Newsletter</VuiTypography>
      <div style={borderStyle}></div>
      <VuiTypography variant={window.innerWidth <=800 ? 'h6' : 'h5'} color='white' textAlign='center' mt="20px" mb="20px" sx={{letterSpacing: '0.8px'}}>Subscribe to our newsletter to stay updated for more features and blogs. </VuiTypography>
      <VuiInput placeholder='Enter your name' sx={{width: window.innerWidth <= 800 ? '200px !important' : '300px !important', marginBottom: '5px'}} />
      <VuiInput placeholder='Enter your email' sx={{width: window.innerWidth <= 800 ? '200px !important' : '300px !important'}} />
      <VuiBox sx={{marginTop: '20px', display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: '250px'}}>
        <VuiButton>Subscribe</VuiButton>
        <VuiButton variant='outlined'>No, thanks</VuiButton>
      </VuiBox>
    </VuiBox>
  )
}

export default Newsletter