import React, { useState } from 'react';
import Logo from './Logo 1.3b9ed676.svg';
// import Logo from './Screenshot 2023-12-19 at 2.19.42 PM.png';
import bells from './christmas-decorations_3693441-removebg-preview.png';
import './Navbar.css';
import Button from '@mui/material/Button';
import { NavLink } from "react-router-dom";
import MenuIcon from '@mui/icons-material/Menu';
import CloseIcon from '@mui/icons-material/Close';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import {BiBot, BiVideo, BiSolidMagicWand} from 'react-icons/bi';
import { FaWandMagicSparkles } from "react-icons/fa6";
import { MdOutlineVideoSettings } from "react-icons/md";
import { SiMarketo } from "react-icons/si";
import { RiCustomerService2Line } from "react-icons/ri";
import { ExpandLess } from '@mui/icons-material';

const Navbar = ({ scrollToSection }) => {
  const [showMenu, setShowMenu] = useState(false);
  const [showSolutionMenu, setShowSolutionMenu] = useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [anchorSolutionEl, setAnchorSolutionEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const openSolution = Boolean(anchorSolutionEl);

  const [anchorElFeatures, setAnchorElFeatures] = useState(null);
   const [openFeatures, setOpenFeatures] = useState(false);

  const handleClick = (event) => { 
    setAnchorEl(event.currentTarget);
  };
  
  const handleSolutionClick = (event) => { 
    setAnchorSolutionEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  
  const handleSolutionClose = () => {
    setAnchorSolutionEl(null);
  };

  const toggleMenu = () => {
    setShowMenu(!showMenu);
  };
  
  const toggleSolutionMenu = () => {
    setShowSolutionMenu(!showMenu);
  };

  const redirectToSignIn = () => {
    window.open('https://app.quickvideo.ai/signin', '_blank');
  };
  
  const redirectToSignUp = () => {
    window.open('https://app.quickvideo.ai/signup', '_blank');
  };

  const handleFeaturesClick = (event) => {
    setAnchorElFeatures(event.currentTarget);
    setOpenFeatures(true);
  };
  
  const handleFeaturesClose = () => {
    setAnchorElFeatures(null);
    setOpenFeatures(false);
  };

  const runTwoTimes = async (path) => {
    await scrollToSection(path);
    await scrollToSection(path);
  }

  return (
    <div className='navbar'>
      <div style={{display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: window.innerWidth <= 800 ? '100%' : ''}}>
        <MenuIcon className='menuIcon' style={{ fontSize: 30, color: 'white', display: window.innerWidth <= 1000 ? 'block' : 'none', cursor: 'pointer', marginRight: 5 }} onClick={toggleMenu} />
        <NavLink to="/" style={{color: 'whitesmoke'}}>
          <img src={Logo} alt="logo" height='70px' style={{width: window.innerWidth <= 800 ? '150px' : '', marginTop: '10px'}} onClick={() => {scrollToSection('home');}} loading="lazy" />
          {/* <img src={Logo} alt="logo" height='60px' style={{width: window.innerWidth <= 800 ? '150px' : '', marginTop: '-10px'}} onClick={() => {scrollToSection('home');}} loading="lazy" /> */}
        </NavLink>
      </div>
      <div className={`navbar_items ${showMenu || showSolutionMenu ? 'show' : ''}`}>
        {window.innerWidth <= 1000 ? <CloseIcon sx={{position: 'absolute', top: '10px', left: '22px'}} onClick={toggleMenu} /> : ''}
        <div 
          id="basic-button"
          aria-controls={open ? 'basic-menu' : undefined}
          aria-haspopup="true"
          aria-expanded={open ? 'true' : undefined}
          onClick={handleClick}
          style={{display: 'flex', alignItems: 'center', marginBottom: window.innerWidth <= 800 ?'10px' : '', marginLeft: window.innerWidth <= 800 ? '10px' : ''}}
        >
          <h5>Products</h5>
          {anchorEl ? <ExpandLess /> : <ExpandMoreIcon />}
        </div>
        {/* <div 
          id="basic-button"
          aria-controls={openSolution ? 'basic-solution-menu' : undefined}
          aria-haspopup="true"
          aria-expanded={openSolution ? 'true' : undefined}
          onClick={handleSolutionClick}
          style={{display: 'flex', alignItems: 'center', marginBottom: window.innerWidth <= 800 ?'10px' : '', marginLeft: window.innerWidth <= 800 ? '10px' : ''}}
        >
          <h5>Solutions</h5>
          <ExpandMoreIcon />
        </div> */}

        {/* <NavLink to="/" style={{color: 'whitesmoke'}} onClick={toggleMenu}>
          <h5 onClick={() => {scrollToSection('features');}}>Features</h5>
        </NavLink> */}


        <div 
          id="basic-button"
          aria-controls={open ? 'features-menu' : undefined}
          aria-haspopup="true"
          aria-expanded={open ? 'true' : undefined}
          onClick={handleFeaturesClick}
          style={{display: 'flex', alignItems: 'center', marginBottom: window.innerWidth <= 800 ?'10px' : '', marginLeft: window.innerWidth <= 800 ? '10px' : ''}}
        >
          <h5>Features</h5>
          {anchorElFeatures ? <ExpandLess /> : <ExpandMoreIcon />}
        </div>

        {/* <NavLink to="/home" style={{color: 'whitesmoke'}} onClick={toggleMenu}>
          <h5>Solutions</h5>
        </NavLink> */}
        <Menu
          id="basic-menu"
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          sx={{
            mt: 1.5,
          }}
        >
          <NavLink to="/text-to-video-ai-generator" style={{color: 'whitesmoke'}} onClick={toggleMenu}>
            <MenuItem onClick={()=>{handleClose();}}><BiVideo size="18px" style={{marginRight: '10px'}} /> Text-to-Video</MenuItem>
          </NavLink>
          <NavLink to="/personalized-ai-video" style={{color: 'whitesmoke'}} onClick={toggleMenu}>
            <MenuItem onClick={()=>{handleClose();}}><BiSolidMagicWand size="18px" style={{marginRight: '10px'}} /> Video Personalization </MenuItem>
          </NavLink>
          <NavLink to="/conversational-ai" style={{color: 'whitesmoke'}} onClick={toggleMenu}>
            <MenuItem onClick={()=>{handleClose();}}><BiBot size="18px" style={{marginRight: '10px'}} /> Conversational Video Bot</MenuItem>
          </NavLink>
          <NavLink to="/saas-tutorial" style={{color: 'whitesmoke'}} onClick={toggleMenu}>
            <MenuItem onClick={()=>{handleClose();}}><MdOutlineVideoSettings size="18px" style={{marginRight: '10px'}} /> Generate Tutorial Video</MenuItem>
          </NavLink>
        </Menu>


        <Menu
        id="features-menu"
        anchorEl={anchorElFeatures}
        open={openFeatures}
        onClose={handleFeaturesClose}
        sx={{ mt: 1.5 }}
      >
        <a 
         href="https://www.quickvideo.ai/features/features-languages.html" 
         style={{ color: 'whitesmoke' }} 
         onClick={toggleMenu}>
         <MenuItem onClick={handleFeaturesClose}>Multilingual AI Voices</MenuItem>
        </a>

        <a 
          href="https://www.quickvideo.ai/features/features-video-library.html" 
          style={{ color: 'whitesmoke' }} 
          onClick={toggleMenu}>
          <MenuItem onClick={handleFeaturesClose}>Video Library</MenuItem>
        </a>

        <a 
          href="https://www.quickvideo.ai/features/features-ai-presenters.html" 
          style={{ color: 'whitesmoke' }} 
          onClick={toggleMenu}>
          <MenuItem onClick={handleFeaturesClose}>AI Avatars</MenuItem>
        </a>

        <a 
          href="https://www.quickvideo.ai/features/features-audio.html" 
          style={{ color: 'whitesmoke' }} 
          onClick={toggleMenu}>
          <MenuItem onClick={handleFeaturesClose}>Audio</MenuItem>
        </a>

        <a 
          href="https://www.quickvideo.ai/features/features-video-editor.html" 
          style={{ color: 'whitesmoke' }} 
          onClick={toggleMenu}>
          <MenuItem onClick={handleFeaturesClose}>Video Editor</MenuItem>
        </a>

        <a 
          href="https://www.quickvideo.ai/features/features-voice-cloning.html" 
          style={{ color: 'whitesmoke' }} 
          onClick={toggleMenu}>
          <MenuItem onClick={handleFeaturesClose}>Voice Cloning</MenuItem>
        </a>

      </Menu>


        {/* <Menu
          id="basic-solution-menu"
          anchorEl={anchorSolutionEl}
          open={openSolution}
          onClose={handleSolutionClose}
          sx={{
            mt: 1.5,
          }}
        >
          <NavLink to="/sales&marketing" style={{color: 'whitesmoke'}} onClick={toggleSolutionMenu}>
            <MenuItem onClick={()=>{handleSolutionClose();}}><SiMarketo size="18px" style={{marginRight: '10px'}} />Sales & Marketing</MenuItem>
          </NavLink>
          <NavLink to="/customer_service" style={{color: 'whitesmoke'}} onClick={toggleSolutionMenu}>
            <MenuItem onClick={()=>{handleSolutionClose();}}><RiCustomerService2Line size="18px" style={{marginRight: '10px'}} />Customer Service</MenuItem>
          </NavLink>
          <NavLink to="/enterprises_employee_engagement" style={{color: 'whitesmoke'}} onClick={toggleSolutionMenu}>
            <MenuItem onClick={()=>{handleSolutionClose();}}><RiCustomerService2Line size="18px" style={{marginRight: '10px'}} />Enterprises/Employee Engagement</MenuItem>
          </NavLink>
          <NavLink to="/education" style={{color: 'whitesmoke'}} onClick={toggleSolutionMenu}>
            <MenuItem onClick={()=>{handleSolutionClose();}}><RiCustomerService2Line size="18px" style={{marginRight: '10px'}} />Education</MenuItem>
          </NavLink>
          <NavLink to="/learning&development" style={{color: 'whitesmoke'}} onClick={toggleSolutionMenu}>
            <MenuItem onClick={()=>{handleSolutionClose();}}><RiCustomerService2Line size="18px" style={{marginRight: '10px'}} />Learning & Development</MenuItem>
          </NavLink>
          <NavLink to="/socialmedia" style={{color: 'whitesmoke'}} onClick={toggleSolutionMenu}>
            <MenuItem onClick={()=>{handleSolutionClose();}}><RiCustomerService2Line size="18px" style={{marginRight: '10px'}} />Social Media</MenuItem>
          </NavLink>
        </Menu> */}
        <NavLink to="/" style={{color: 'whitesmoke'}} onClick={toggleMenu}>
          <h5 onClick={() => runTwoTimes('information')}>How It Works</h5>
        </NavLink>
        {/* <NavLink to="/faq" style={{color: 'whitesmoke'}} onClick={toggleMenu}>
          <h5 onClick={() => {scrollToSection('questions'); setShowPricing(false); setShowContact(false);}}>FAQs</h5>
        </NavLink> */}
        <NavLink to="/blogs" style={{color: 'whitesmoke'}}>
          <h5>Blogs</h5>
        </NavLink>
        <NavLink to="/quickvideo-pricing" style={{color: 'whitesmoke'}} onClick={toggleMenu}>
          <h5>Pricing</h5>
        </NavLink>
        <NavLink to="/" style={{color: 'whitesmoke'}} onClick={toggleMenu}>
          <h5 onClick={() => runTwoTimes('contact')}>Contact Us</h5>
        </NavLink>
        <div style={{display: window.innerWidth <= 800 ? 'flex' : 'none', alignItems: 'center', justifyContent: 'center', flexWrap: 'nowrap'}}>
          <Button sx={{background: 'white', color: '#000', borderRadius: window.innerWidth <= 800 ?'10px' : '30px', fontWeight: 600}} variant='contained' >
            <a href="https://calendly.com/quickvideo/demo" target="_blank" rel="noopener noreferrer" style={{marginBottom:"0px",color:"black"}}>
               Book Demo
            </a>
          </Button>
          <Button  sx={{background: 'white', color: '#000', borderRadius: window.innerWidth <= 800 ?'10px' : '30px', fontWeight: 600, marginLeft: '25px'}} variant='contained' onClick={redirectToSignUp}>Free Trial</Button>
        </div>
      </div>
      <div style={{display: window.innerWidth <= 800 ? 'none' : 'flex', flexWrap: 'nowrap'}}>
        {/* <button sx={{background: 'white', color: '#000', borderRadius: window.innerWidth <= 800 ?'10px' : '30px', fontWeight: 600, marginLeft: '25px', '&:hover': {color: "#fff"},}} variant='contained' onClick={redirectToSignIn}>Schedule demo</button> */}
        {/* <button onClick={redirectToSignIn}>Schedule demo</button> */}
        <a style={{'--clr': '#ffffff'}} class="button" href="https://calendly.com/quickvideo/demo" target="_blank" rel="noopener noreferrer">
          <span class="button__icon-wrapper">
              <svg width="10" class="button__icon-svg" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 15">
                  <path fill="currentColor" d="M13.376 11.552l-.264-10.44-10.44-.24.024 2.28 6.96-.048L.2 12.56l1.488 1.488 9.432-9.432-.048 6.912 2.304.024z"></path>
              </svg>
              
              <svg class="button__icon-svg  button__icon-svg--copy" xmlns="http://www.w3.org/2000/svg" width="10" fill="none" viewBox="0 0 14 15">
                  <path fill="currentColor" d="M13.376 11.552l-.264-10.44-10.44-.24.024 2.28 6.96-.048L.2 12.56l1.488 1.488 9.432-9.432-.048 6.912 2.304.024z"></path>
              </svg>
          </span>
          Book Demo
        </a>
        <div style={{'--clr': '#ffffff', marginLeft: '10px', cursor: 'pointer', gridGap:'0.2rem'}} class="button" onClick={redirectToSignUp}>
          Free Trial  <KeyboardArrowRightIcon />
        </div>
        {/* <Button  sx={{background: 'white', color: '#000', borderRadius: window.innerWidth <= 800 ?'10px' : '30px', fontWeight: 600, marginLeft: '25px', '&:hover': {color: "#fff"},}}  variant='contained' onClick={redirectToSignUp}>Free Trial</Button> */}
      </div>
      {/* <img src={bells} alt="logo" height='60px' style={{width: window.innerWidth <= 800 ? '150px' : '', marginTop: '10px', transform: 'scaleX(-1)', position: 'fixed', zIndex: '1000', top: '-1%', right: '3%', filter: 'invert(1)'}} onClick={() => {scrollToSection('home');}} loading="lazy" /> */}
    </div>
  )
}

export default Navbar;