/* eslint-disable */

import img1 from './img/background-fireworks-dark-sky_1308-36481.avif';
import img2 from './img/bokeh-background-perfect-canva.jpg';
import img3 from './img/cute-christmas-background-with-bokeh_1361-3548.avif';
import img4 from './img/merry-christmas-card-perfect-you-text.jpg';
import img5 from './img/adi-goldstein-Hli3R6LKibo-unsplash.jpg';
import img6 from './img/al-elmes-ULHxWq8reao-unsplash.jpg';
import img7 from './img/annie-spratt-B7ohH4oRSaE-unsplash.jpg';
import img8 from './img/boliviainteligente-NncoPnT_e_I-unsplash.jpg';
import img9 from './img/chad-madden-SUTfFCAHV_A-unsplash.jpg';
import img10 from './img/darren-nunis-cxE7SXKnzv0-unsplash.jpg';
import img11 from './img/emmanuel-cassar-gnYipbCBzbw-unsplash.jpg';
import img12 from './img/galina-kondratenko-k94vnHTFyqQ-unsplash.jpg';
import img13 from './img/jason-leung-Xaanw0s0pMk-unsplash.jpg';
import img14 from './img/kent-lee-9bG50r7S0cg-unsplash.jpg';
import img15 from './img/roberto-sorin-QF6vHYumbCw-unsplash.jpg';

// export default [
//   img1, img4, img6, img7, 
//   img11, img12, img13, img15 
// ]
export default [
  'https://ik.imagekit.io/iohh3uasj9/QuickVideo/img/background-fireworks-dark-sky_1308-36481.avif?updatedAt=1703833227883', 
  'https://ik.imagekit.io/iohh3uasj9/QuickVideo/img/merry-christmas-card-perfect-you-text.jpg?updatedAt=1703833227944',
  'https://ik.imagekit.io/iohh3uasj9/QuickVideo/img/al-elmes-ULHxWq8reao-unsplash.jpg?updatedAt=1703833227939',
  'https://ik.imagekit.io/iohh3uasj9/QuickVideo/img/annie-spratt-B7ohH4oRSaE-unsplash.jpg?updatedAt=1703833227906',
  'https://ik.imagekit.io/iohh3uasj9/QuickVideo/img/emmanuel-cassar-gnYipbCBzbw-unsplash.jpg?updatedAt=1703833227931',
  'https://ik.imagekit.io/iohh3uasj9/QuickVideo/img/galina-kondratenko-k94vnHTFyqQ-unsplash.jpg?updatedAt=1703833227991',
  'https://ik.imagekit.io/iohh3uasj9/QuickVideo/img/jason-leung-Xaanw0s0pMk-unsplash.jpg?updatedAt=1703833227957',
  'https://ik.imagekit.io/iohh3uasj9/QuickVideo/img/roberto-sorin-QF6vHYumbCw-unsplash.jpg?updatedAt=1703833228091',
]